/* ------------Banner section Start ---------------*/
.banner-main {
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
}

.banner-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner-content {
  position: absolute;
  width: 60%;
  height: 65%;
  top: 50px;
  padding-left: 50px;
  /* padding: 20px;  */
  color: #fff;
}

.banner-content h2 {
  font-size: 53px;
  font-weight: 700;
}

.banner-content h1 {
  font-size: 78px;
  color: #C9784A;
  font-weight: 700;
  margin-bottom: 10px;

}

.banner-content p {
  font-size: 35px;
  height: 50%;
  width: 75%;
  font-weight: normal;
  letter-spacing: 2px;
  line-height: 60px;
  margin-bottom: 20px;
}

.banner-content p strong {
  font-weight: bold;
}

.banner-content button {
  background-color: #C9784A;
  width: 255px;
  height: 75px;
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  border: none;
  margin-top: 30px;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.5s;
}

.banner-content button:hover {
  color: #C9784A;
  background-color: #ffffff;

}



/* .fade-in1 {
    opacity: 0;
    animation: fadeIn1 0.2s ease-out forwards;
  }
  
  .fade-in2 {
    opacity: 0;
    animation: fadeIn2 0.4s ease-out forwards;
  }
  
  .fade-in3 {
    opacity: 0;
    animation: fadeIn3 0.6s ease-out forwards;
  } */

.fade-in4 {
  opacity: 0;
  animation: fadeIn4 2s ease-out forwards;
}

@keyframes fadeIn1 {
  from {
    opacity: 0;
    transform: translateX(-50px) translateY(-1px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn2 {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn3 {
  from {
    opacity: 0;
    transform: translateX(-150px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn4 {
  from {
    opacity: 0;
    transform: translateX(-200px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.bg {
  background-color: #C9784A;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0,0,0,.3); */
}

.overlay2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0,0,0,.5); */
}

.banner-1-para {
  color: black;
}

@media (max-width:767px) {
  .banner-main {
    position: relative;
    flex-direction: column;
    height: 32vh;
  }

  .banner-1-para {
    color: black;
    padding: 0px 35px;
    text-shadow: 5px 5px 5px white;
  }

  .banner-image {
    width: 100%;
    height: 100%;
    background-size: cover;
  }

  .banner-video {
    width: 100%;
    height: 100%;
  }

  .banner-content {
    width: 100%;
    /* height: 40vh; */
    text-align: center;
    padding: 0px;
  }

  .banner-content h2 {
    font-size: 20px;
    padding: 0px;
  }

  .banner-content h1 {
    font-size: 26px;
    font-weight: 800;
  }

  .banner-content p {
    font-size: 15px;
    letter-spacing: 0px;
    line-height: 15px;
    width: 100%;
  }

  .banner-content button {
    width: 120px;
    font-size: 15px;
    height: 40px;
    margin-top: 0px;
  }

  .banner-tag {
    display: none;
  }

  .m-margin {
    margin-right: 255px;
  }

}

@media (min-width:800px) and (max-width:1024px) {
  .banner-main {
    height: 60vh;
  }

  .banner-content {
    width: 70%;
    height: 100%;
  }

  .banner-content p {
    height: 70%;
  }
}


@media (min-width:768px) and (max-width:991px) {
  .banner-content {
    width: 70%;
    height: 100%;
  }

  .banner-content h2 {
    font-size: 30px;
  }

  .banner-content h1 {
    font-size: 40px;
  }

  .banner-content p {
    font-size: 23px;
    line-height: 30px;
  }

  .banner-1-para {
    font-size: 20px;
  }

  .banner-main {
    position: relative;
    flex-direction: column;
    height: 500px;
  }

  .banner-image {
    width: 100%;
    height: 100%;
  }

  .banner-video {
    width: 100%;
    height: 100%;

  }

  .banner-tag {
    position: absolute;
    width: 45%;
    height: 35%;
    bottom: -40px;
    right: -40px;
  }

  .banner-content button {
    width: 150px;
    height: 40px;
    font-size: 15px;
    margin-top: -90px;
  }

}


@media (min-width:992px) {
  .banner-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .banner-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .banner-tag {
    position: absolute;
    width: 60%;
    height: 50%;
    bottom: -50px;
    right: -100px;
  }
}

/* iPad Air  */
@media (min-width:800px) and (max-width:1024px) {
  .banner-main {
    height: 500px;
  }

  .banner-tag {
    width: auto;
    right: -40px;
    bottom: 0;
  }

  .banner-content {
    height: 75%;
  }
}
/* 
@media only screen and (min-width: 1200px) {
  .banner-main{
    height: 90vh;
  }
  .banner-content{
    height: 75%;
  }
} */


/* iPad Pro */
@media only screen and (min-width:903px) and (max-device-width: 1194px) and (-webkit-min-device-pixel-ratio: 2) {
  .banner-main {
    height: 57vh;
  }

  .banner-tag {
    width: auto;
    right: -80px;
    bottom: -85px;
  }

  .banner-content h1 {
    font-size: 65px;
  }

  .banner-content button {
    margin-top: -90px;
  }

}

/* Banner section End */