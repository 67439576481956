/* -----------------------banner css  start-----------------------------  */
.contactus-banner {
  background-image: url("../img/Contactus/contactus-banner-image.jpg");
  height: 80vh;
  width: 100%;
  background-size: 105%;
  background-position: 70% 70%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.cardname1 textarea {
  height: 100%;
  box-sizing: border-box; /* Include padding and border in the element's total height */
}

.cardname input[type="number"]::-webkit-inner-spin-button,
.cardname input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cardname input[type="number"] {
  -moz-appearance: textfield;
}
.tagline-col {
  padding: 120px;
}

.brand-name {
  color: #cf8453;
  font-weight: 700;
  font-size: 60px;
}
.join-image {
  width: 130px;
}
.tagline-image {
  width: 80%;
}

@media (max-width: 767px) {
  .contactus-banner {
    background-image: url("../img/Contactus/contactus-banner-image.jpg");
    height: max-content;
    width: 100%;
    height: 30vh;
    background-repeat: no-repeat;
  }
  .tagline-col {
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 0px;
  }
  .brand-name {
    color: #cf8453;
    font-weight: 700;
    font-size: 17px;
    margin-left: 20px;
  }
  .tagline-image {
    width: 40%;
    margin-left: 20px;
  }
  .join-image {
    margin-left: 20px;
    width: 60px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .contactus-banner {
    background-image: url("../img/Contactus/contactus-banner-image.jpg");
    height: 500px;
    width: 100%;
    background-repeat: no-repeat;
  }
  .tagline-col {
    padding: 70px 0px 0px 40px;
  }
  .join-image {
    width: 90px;
  }
  .brand-name {
    font-size: 35px;
  }
  .tagline-image {
    width: 55%;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .contactus-banner {
    height: 50vh;
  }
}

/* -----------------------banner css end-----------------------------  */
/* -----------------------form content css start-----------------------------  */

.contact-tagline {
  font-weight: 600;
  font-size: xx-large;
  margin-top: 100px;
}
.colorfont-tag {
  color: #cf8453;
  font-weight: 600;
  font-size: xx-large;
}
.contact-image {
  height: 350px;
  width: 80%;
}
.font-section-row {
  background: linear-gradient(
      rgba(255, 255, 255, 0.822),
      rgba(255, 255, 255, 0.842)
    ),
    linear-gradient(rgba(255, 255, 255, 0.144), rgba(255, 255, 255, 0.144)),
    url(../img/Contactus/RElogo-background-contactus.png) center no-repeat;
  width: 100%;
  background-size: cover;
  height: 700px;
}
.contact-input {
  border: none;
  height: 100%;
}

.cardinput1 {
  background-color: #009846;
  min-height: 100px;
  width: 70%;
  padding: 20px;
  border-radius: 25px;
  margin-top: 60px;
  margin-left: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.75) 0px 25px 20px -20px;
}

@media (max-width: 319px) {
  .contact-image {
    height: 200px;
    width: 80%;
  }
  .contact-tagline {
    margin-top: 20px;
    font-size: 17px;
  }
  .colorfont-tag {
    font-size: 19px;
  }
  .font-section-row {
    background: linear-gradient(
        rgba(255, 255, 255, 0.822),
        rgba(255, 255, 255, 0.842)
      ),
      linear-gradient(rgba(255, 255, 255, 0.144), rgba(255, 255, 255, 0.144)),
      url(../img/Contactus/RElogo-background-contactus.png) center no-repeat;
    width: 100%;
    background-size: cover;
    height: max-content;
  }

  .cardinput1 {
    background-color: #009846;
    min-height: 250px;
    margin-bottom: 30px;
    width: 90%;
    padding: 5px;
    padding-bottom: 15px;
    border-radius: 25px;
    margin-top: 20px;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .buttoncontact2 {
    width: 110px;
    height: 35px;
    line-height: 15px;
    font-size: 13px;
    font-weight: bold;
    background-color: black;
    margin-left: 10px;
  }
}

@media (min-width: 320px) and (max-width: 411px) {
  .contact-tagline {
    margin-top: 20px;
  }
  .font-section-row {
    background: linear-gradient(
        rgba(255, 255, 255, 0.822),
        rgba(255, 255, 255, 0.842)
      ),
      linear-gradient(rgba(255, 255, 255, 0.144), rgba(255, 255, 255, 0.144)),
      url(../img/Contactus/RElogo-background-contactus.png) center no-repeat;
    width: 100%;
    background-size: cover;
    height: max-content;
  }

  .cardinput1 {
    background-color: #009846;
    min-height: 300px;
    margin-bottom: 30px;
    width: 80%;
    padding: 5px;
    padding-bottom: 15px;
    border-radius: 25px;
    margin-top: 20px;
    margin-left: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .buttoncontact2 {
    width: 110px;
    height: 35px;
    line-height: 15px;
    font-size: 13px;
    font-weight: bold;
    background-color: black;
    margin-top: 10px;
    margin-left: 10px;
  }
}

@media (min-width: 412px) and (max-width: 767px) {
  .contact-tagline {
    margin-top: 20px;
  }
  .font-section-row {
    background: linear-gradient(
        rgba(255, 255, 255, 0.822),
        rgba(255, 255, 255, 0.842)
      ),
      linear-gradient(rgba(255, 255, 255, 0.144), rgba(255, 255, 255, 0.144)),
      url(../img/Contactus/RElogo-background-contactus.png) center no-repeat;
    width: 100%;
    background-size: cover;
    height: max-content;
  }

  .cardinput1 {
    background-color: #009846;
    min-height: 300px;
    margin-bottom: 30px;
    width: 80%;
    padding: 5px;
    padding-bottom: 15px;
    border-radius: 25px;
    margin-top: 20px;
    margin-left: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .buttoncontact2 {
    width: 115px;
    height: 35px;
    line-height: 15px;
    font-size: 15px;
    font-weight: bold;
    background-color: black;
    margin-top: 10px;
    margin-left: 10px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .cardinput1 {
    margin-bottom: 30px;
  }
  .font-section-row {
    background: linear-gradient(
        rgba(255, 255, 255, 0.822),
        rgba(255, 255, 255, 0.842)
      ),
      linear-gradient(rgba(255, 255, 255, 0.144), rgba(255, 255, 255, 0.144)),
      url(../img/Contactus/RElogo-background-contactus.png) center no-repeat;
    width: 100%;
    background-size: cover;
    height: max-content;
  }
  .buttoncontact2 {
    margin-bottom: 15px;
  }
  .contact-tagline {
    margin-top: 50px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .cardinput1 {
    margin-top: 150px;
  }
}

/* -----------------------form content css end-----------------------------  */
