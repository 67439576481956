 /* -----------------Footer Start-------------------- */
 .logo-image{
    width: 250px;
    margin-left: -15px;
}

.font-size{
    font-size: 13px;
}
.font-size-20{
    font-size: 18px;
}

.footer-background {
    position: relative;
    width: 100%;
    height: 460px;
    background: 
        linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        linear-gradient(rgba(14, 2, 72, 0.144), rgba(14, 2, 72, 0.144)),
        url(../img/Home/grid3.png) center no-repeat;
    background-size: cover;
}
.footer-container{
    padding: 0px 40px;
}

.footer-main-responsive{
    padding: 15px 80px;
}
.social-medias a i {
    font-size: 25px;
}
.footer-contact-us a {
    text-decoration: none;
    color: white; /* Set the desired text color */
  }
  

.footer-top{
    position: relative;
    padding-top: 20px;
}

.footer-top h3{
    font-family: 'Inter',sans-serif;
    font-size: 25px;
}
.footer-contact-us h3 ,.footer-quick-link li h3 , .footer-legal li h3, .footer-head-office h3{
    font-size: 23px;
    font-family: 'Inter',sans-serif;
    font-weight: 500;
}
.footer-contact-us p, .footer-quick-link li a, .footer-Legal li a, .footer-head-office p{
    font-size: 18px;
    font-family: 'Inter',sans-serif;
    font-weight: 400;
   
}
.footer-Legal li h3{
    font-size: 23px;
}

.copy-rights{
    font-size: 19px;
    font-weight: 400;
}
@media (max-width:319px){
    .footer-background {
        min-height: 800px;

    }
    .footer-main-responsive{
        padding: 14px 0px;
    }
    .footer-contact-us p{
        font-size: 14px;
        
    }
    .footer-contact-us h3{
        font-size: 18px;
        margin-top: -20px;
    }
    .footer-container{
        padding: 0px 25px;
    }
    .social-medias a i {
        font-size: 23px;
        margin-top: -10px;
        margin-bottom: -40px
    }
    .footer-top h3{
        font-family: 'Inter',sans-serif;
        font-weight: 400;
        font-size: 18px;
        margin-top: -10px;
    }
    .footer-contact-us p{
        font-size: 13px;
        font-family: 'Inter',sans-serif;
        font-weight: 400
    }
    .footer-quick-link li a{
       
        font-size: 16px;
        font-family: 'Inter',sans-serif;
        font-weight: 400;
        margin-top: -10px;
        margin-left: 15px;

    }
    .footer-contact-us h3 ,.footer-quick-link li h3 {
        font-size: 20px;
        font-family: 'Inter',sans-serif;
        font-weight: 500;  
    }
    
    .footer-Legal li a{
        font-size: 15px;
        margin-top: -15px;
        margin-left: 15px;

    }
    .footer-Legal li h3{
        font-size: 20px;

    }
    .footer-head-office h3{
        font-size: 20px;
    }
    .footer-head-office p{
        font-size: 15px;
        margin-top: -15px;
        margin-left: 25px;
    }
    .city-name p{
        font-size: 15px;
        margin-top: -10px;
        margin-left: 15px;
    }
    .copy-rights{
        font-size: 13px;
    }
   .legal-text{
    font-size: 15px;
   }
}

@media (min-width:320px) and (max-width:767px){
    .footer-background {
        min-height: 820px;
        /* height: max-content; */

    }
    .footer-main-responsive{
        padding: 14px 0px;
    }
    .footer-contact-us p{
        font-size: 14px;
        
    }
    .footer-contact-us h3{
        font-size: 18px;
        margin-top: -20px;
    }
    .footer-container{
        padding: 0px 30px;
    }
    .social-medias a i {
        font-size: 23px;
        margin-top: -10px;
        margin-bottom: -40px
    }
    .footer-top h3{
        font-family: 'Inter',sans-serif;
        font-weight: 400;
        font-size: 18px;
        margin-top: -10px;
    }
    .footer-contact-us p{
        font-size: 16px;
        font-family: 'Inter',sans-serif;
        font-weight: 400
    }
    .footer-quick-link li a{
       
        font-size: 16px;
        font-family: 'Inter',sans-serif;
        font-weight: 400;
        margin-top: -10px;
        margin-left: 15px;

    }
    .footer-contact-us h3 ,.footer-quick-link li h3 {
        font-size: 20px;
        font-family: 'Inter',sans-serif;
        font-weight: 500;  
    }
    
    .footer-Legal li a{
        font-size: 15px;
        margin-top: -15px;
        margin-left: 15px;

    }
    .footer-Legal li h3{
        font-size: 20px;

    }
    .footer-head-office h3{
        font-size: 20px;
    }
    .footer-head-office p{
        font-size: 15px;
        margin-top: -15px;
        margin-left: 25px;
    }
    .city-name p{
        font-size: 15px;
        margin-top: -10px;
        margin-left: 15px;
    }
    .copy-rights{
        font-size: 15px;
        margin-top: 10px;
    }
   .legal-text{
    font-size: 15px;
   }
   
   
}

@media (min-width:768px) and (max-width:991px){
    .footer-background {
        height: max-content;
        width: 100%;
    }
    .footer-container{
        padding: 0px 0px;
    }
    .footer-top{
        padding-top: 0px;
    }
    .footer-main-responsive{
        padding: 15px 60px;
    }
    .footer-head-office{
        position: absolute;
        top: 240px;
        left: 240px;
    }
    .footer-head-office p{
        margin-left: 20px;
    }
   
    
    .copy-rights{
        margin-top: 10px;
    }
}
/* --------------Footer End------------------ */