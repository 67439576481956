/*-------------------------------- new video content start--------------------- */

.news-text{
    font-size: 40px;
    font-weight: 700;
}
.news-card{
    position: relative;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    width: 24rem;
}
.youtube-video{
    width: 24rem;
    height: 230px;
}
.card-media-title{
    text-decoration: underline;
    line-height: 22px;
}
.card-media-title p {
    font-size: 22px;
    font-weight: 700;
    color: black;
    text-decoration: underline black;
}
.card-text{
    font-size: 17px;
    font-weight: 500;
}

.read-more-button{
    display: flex;
    justify-content: flex-end;
    float: right;
    text-decoration: underline;
    border:none;
    
}
.read-more-button p{
    font-weight: 500; 
}
a.btn-button:hover {
    /* Set the hover color you want */
    color: #C68955; /* Replace with your desired color */
  }
.card-media-title p:hover {
    color: #C68955;
}


a.article-read-more-button:hover {
    /* Set the hover color you want */
    color: #C68955; /* Replace with your desired color */
  }
.article-card-title:hover {
    color: #C68955;
}




.article-image{
    width: 24rem;
    height: 230px;
}
.article-card-title{
    font-size: 22px;
    height: 50px;
    font-weight: 700;
    color: black;
    text-decoration: underline;
    line-height: 22px;
}
.article-card-gallery-title{
    font-size: 22px;
    font-weight: 700;
    list-style-type: none;
    text-align: center;
    color: black;
    text-decoration: underline;
    line-height: 22px;
}
.article-card-gallery-title .gallery-link{
    color: black;
    text-align: center;
    font-family: 'Genos';

}

.article-card-text{
    height: 100px;
    font-size: 15px;
    font-weight: 500;
}

.article-read-more-button{
    display: flex;
    justify-content: flex-end;
    float: right;
    text-decoration: underline;
}
.article-read-more-button-last{
    display: flex;
    justify-content: flex-end;
    float: right;
    text-decoration: underline;
    margin-top: -17px;
}
.article-read-more-button p{
    font-size: 15px;
    font-weight: 700; 
}
.article-read-more-button-last p{
    font-size: 15px;
    font-weight: 700; 
}

.gallery-image{
    width: 24rem;
    height: 280px;
}
.gallery-link:hover{
    color: #C68955;
}


/*-------------------------------- new video content start--------------------- */
@media (max-width:319px) {
    .news-card{
        border: none;
        box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
        width: 15rem;
    }
    c.card-media-title p {
        font-size: 17px;
    }
   
    .ard-text{
        font-size: 15px;
        text-align: center;
    }
    .youtube-video{
        width: 15rem;
        height: 230px;
    }
    .read-more-button p{
       font-size: 15px;
    }
    .article-image{
        width: 15rem;
        height: 230px;
    }
    .article-card-title{
        font-size: 17px;
    }
    .article-card-text{
        height: 135px;
        font-size: 15px;
        font-weight: 500;
    }
    .gallery-image{
        width: 15rem;
        height: 250px;
    }
    .m-padding{
        padding-bottom: 20px;
    }
    .news-text{
        text-align: center;
    }
    .article-read-more-button{
      margin-top: 30px;
    }
    .article-read-more-button-last{
        margin-top: 10px;
    }


}

@media (min-width:320px) and (max-width:411px) {
    .news-card{
        border: none;
        box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
        width: 18rem;
    }
    .card-media-title p {
        font-size: 17px;
    }
   
    .card-text{
        font-size: 15px;
    }
    .youtube-video{
        width: 18rem;
        height: 230px;
    }
    .read-more-button p{
       font-size: 15px;
    }
    .article-image{
        width: 18rem;
        height: 230px;
    }
    .article-card-title{
        font-size: 17px;
    }
    .article-card-text{
        height: 135px;
        font-size: 15px;
        font-weight: 500;
    }
    .gallery-image{
        width: 18rem;
        height: 250px;
    }
    .m-padding{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px;
    }
    .news-text{
        text-align: center;
    }
}

@media (min-width:412px) and (max-width:767px) {
    .news-card{
        border: none;
        box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
        width: 22rem;
    }
    .card-media-title p {
        font-size: 17px;
    }
   
    .card-text{
        font-size: 15px;
        text-align: center;
    }
    .youtube-video{
        width: 22rem;
        height: 230px;
    }
    .read-more-button p{
       font-size: 15px;
    }
    .article-image{
        width: 22rem;
        height: 230px;
    }
    .article-card-title{
        font-size: 17px;
    }
    .article-card-text{
        height: 135px;
        font-size: 15px;
        font-weight: 500;
    }
    .gallery-image{
        width: 22rem;
        height: 250px;
    }
    .m-padding{
        padding-bottom: 20px;
    }
    .news-text{
        text-align: center;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .news-card{
        width: 20.5rem;
    }
    .youtube-video{
        width: 20rem;
        height: 230px;
    }
    .article-image{
        width: 20rem;
        height: 230px;
    }
    .article-card-text{
        height: 130px;
        font-size: 15px;
        font-weight: 500;
    }
    .article-last-card-padding{
        padding-top: 30px;
    }
    .gallery-image{
        width: 20rem;

    }
}
@media only screen and (min-width:1024px) and (max-width:1366px) {
    .news-card{
        width: 19rem;
        height: 500px;
    }
    .youtube-video{
        width: 19rem;
    }
    .article-image{
        width: 19rem;
    }
    .gallery .news-card{
        width: 19rem;
        height: 420px;
    }
    .gallery-image{
        width: 19rem;
    }

}



  /*--------------- Gallery Media1 Section1 Start ---------------*/
    .gallery-media1-title-main{
        width: 100%;
        display: flex;
        justify-content: center;
        font-family: 'Genos';
    }


  .gallery-media1-title{
    width: 600px;
    text-align: center;
    font-size: 35px;
    font-weight: 900;
    font-family: 'Genos';
  }
  
  .gallery-media1-title span{
    color: #C68955;
    font-weight: 900;
    font-size: 40px;
    font-family: 'Genos';
  }

  .gallery-media-section1-main{
    width: 100%;
    height: max-content;
    margin-top: 30px;
  }

  .gallery-media1-img1{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .gallery-media1-img2{
    width: 100%;
    height: 90%;
    object-fit: cover;
    margin-top: 10px;
    padding: 5px;
  }
  .gallery-media1-img3{
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin-bottom: 20px;
    padding: 5px;
  }
  .gallery-media1-img4{
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin-bottom: 20px;
    padding: 5px;
  }
  .gallery-media1-img5{
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin-bottom: 20px;
    padding: 5px;
  }
  @media (max-width:767px) {
    .gallery-media-section1-main{
        width: 100%;
        height: max-content;
        margin-top: 30px;
      }
      .gallery-media1-title{
        font-size: 20px;
      }
      
      .gallery-media1-title span{
        font-size: 20px;

      }
  }

  @media (min-width:768px) and (max-width:991px) {
    .gallery-media-section1-main{
        width: 100%;
        height: max-content;
        margin-top: 30px;
      }

  }
  /*--------------- Gallery Media1 Section1 Start ----------------*/

  
  /*--------------- Gallery Media1 Section2 Start ----------------*/
  .gallery-media1-section-images-main{
    padding: 20px 0px 50px 0px;
  }
  .gallery-media1-section-images{
    display: flex;
    justify-content:center ; 
    margin-top: 50px;
  }
  .gallery-media1-images{
    width: 75%;
    height: 300px;
    object-fit: cover;
  }

  @media (max-width:767px) {
    .gallery-media1-images{
        width: 85%;
        height: 300px;
        object-fit: cover;
      }
  }


   /*--------------- Gallery Media1 Section2 End ----------------*/

    /*-============== Gallery Media2 Section2 Start ==============*/

    .gallery-media2-section1-main{
        width: 100%;
        height: max-content;
        margin: 40px 0px 100px 0px;
    }
    .gallery-media2-section-images-main{
        padding: 0px 0px 150px 0px;
      }

    .gallery-media2-img1{
            width: 100%;
            height: 75%;
            object-fit: cover;
            margin-top: 60px;
    }
    .gallery-media2-img2{
        width: 100%;
        height: 80%;
        object-fit: cover;
    }

    @media (max-width:767px){
        .gallery-media2-section1-main{
            height: max-content;
            margin: 0px 0px 0px 0px;
        }
    }
    @media (min-width:768px) and (max-width:991px) {
        .gallery-media2-section1-main{
            height: max-content;
            margin: 0px 0px 0px 0px;
        }

    }


    /*-============== Gallery Media2 Section2 End ==============*/
    .gallery-media3-section-main{
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .gallery-media3-title{
        width: 600px;
        text-align: center;
        font-size: 37px;
        font-weight: 900;

    }
    .gallery-media3-title span{
        color: #C68955;
        font-weight: 900;
        font-size: 40px;
        font-family: 'Genos';

    }

    .gallery-media3-img{
        width: 75%;
        height: 600px;
    }

    @media (max-width:767px){
        .gallery-media3-img{
            width: 75%;
            height: 250px;
        }
        .gallery-media3-title{
            font-size: 20px;
    
        }
        .gallery-media3-title span{
            font-size: 20px;
    
        }
    }


    @media (min-width:768px) and (max-width:991px) {
        .gallery-media3-img{
            width: 75%;
            height: 400px;
        }

    }