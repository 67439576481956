body {
    overflow-x: hidden;
}

.slick-prev2 {
    cursor: pointer;
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    left: 100px;
    background-color: #C68955;
    border-radius: 50%;
    padding: 2px;
    height: 50px;
    width: 50px;
    z-index: 2;
    font-size: 20px;
    line-height: 25px;
    cursor: pointer;
}
.slick-prev2 i{
    color: white;
}

.slick-prev2:hover {
    background-color: white;
    border: 2px solid #C68955;
    
}

.slick-prev2:hover i {
    color: #C68955;
}

.slick-next2:hover {
    background-color: white;
    border: 2px solid #C68955;
}

.slick-next2:hover i {
    color: #C68955;
}


.slick-prev2 i {
    color: white;
    font-size: 20px;
    font-weight: 900;

}

.slick-next2 {
    cursor: pointer;
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    right: 100px;
    background-color: #C68955;
    border-radius: 50%;
    padding: 2px;
    height: 50px;
    width: 50px;
    font-size: 20px;
    line-height: 25px;
    cursor: pointer;
}

.slick-next2 i {
    color: white;
    font-size: 20px;
    font-weight: 900;
}


@media (max-width:767px) {
    .slick-prev2 {
        cursor: pointer;
        position: absolute;
        top: 40%;
        transform: translateY(-50%);
        left: 40px;
        background-color: #C68955;
        border-radius: 50%;
        padding: 1px;
        height: 15px;
        width: 15px;
        font-size: 20px;
        line-height: 20px;
        cursor: pointer;
    }

    .slick-prev2 i {
        color: white;
        font-size: 18px;
        font-weight: 900;

    }

    .slick-next2 {
        cursor: pointer;
        position: absolute;
        top: 40%;
        transform: translateY(-50%);
        right: 40px;
        background-color: #C68955;
        border-radius: 50%;
        padding: 2px;
        height: 15px;
        width: 15px;
        font-size: 20px;
        line-height: 20px;
        cursor: pointer;
    }

    .slick-next2 i {
        color: white;
        font-size: 18px;
        font-weight: 900;
    }
}

@media (min-width:768px) and (max-width:991px) {

    .slick-prev2 {
        left: 50px;
    }

    .slick-next2 {
        right: 50px;
    }


}

/*------------- carousel arrow End---------------------- */


.about-banner-image{
    width: 100%;
    height: 100%;
}
.banner-container{
    width: 100%;
    height: 80vh;
    position: relative;
}

.banner-container .banner-content-absolute{
    position: absolute;
    top: 40px;
    left: 150px;
}

@media (max-width:767px){
    .banner-container .banner-content-absolute{
        top: 0px;
        left: 10px;
        width: 250px;
    }
    .banner-container{
        /* margin-bottom: -250px; */
        width: 100%;
        height: 30vh;

    }
}
@media (min-width:768px) and (max-width:991px){
    .banner-container{
            width: 100%;
            height: 500px;
    }
    .banner-container .banner-content-absolute{
        top: 30px;
        left: 70px;
        width: 550px;
    }
}
@media only screen and (min-width:1024px) and (max-width:1199px) {
    .banner-container{
        width: 100%;
        height: 50vh;
}
.banner-container .banner-content-absolute{
    top: 20px;
    left: 70px;
    width: 770px;
}

}

/* ----------------------------- aboutus heading code  ----------------------- */
.treeimagebacground {
    background-image: url(../img/Aboutus/abouttreebackground.png);
    background-position: 60%;
    background-size: 105%;
}

.handtreeimage {
    height: 450px;
    width: 650px;
    padding-left: 0px;

}

.cardabouttitle {
    margin-top: 40px;
    padding: 30px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
    border-radius: 30px;
    margin-right: 50px;
}

.cardabouttitle p {
    font-size: 22px;
}

@media (max-width:676px) {
    .handtreeimage {
        /* margin-top: 250px; */
        height: 300px;
        width: 100%;
        padding-left: 0px;

    }

    .treeimagebacground {
        background-image: url(../img/Aboutus/abouttreebackground.png);
        background-position: 20%;
        background-size: 140%;
        z-index: -1;
        width: 100%;
        padding: 0px;
    }

    .cardheadtag {
        padding: 5px;
        font-size: 18px;
        margin-left: 0px;
    }

    .cardabouttitle {
        overflow: hidden;
        margin-left: 20px;
        margin-bottom: 20px;
        font-size: 18px;
    }

    .cardabouttitle p {
        font-size: 18px;
    }

}

@media (min-width: 676px) and (max-width:1023px) {
    .handtreeimage {
        height: 400px;
        width: 600px;
        padding-left: 0px;
    }
    .cardheadtag {
        padding: 0px;
        font-size: 18px;
    }

    .cardabouttitle {
        margin-left: 20px;
        margin-bottom: 20px;
        font-size: 18px;
    }
}
@media only screen and (min-width:1024px) and (max-width:1199px) {
    .cardabouttitle{
        margin-top: 20px;
        margin-bottom: 20px;

    }

}

/* ----------------------------- aboutus heading code end ----------------------- */




/* -----------------------------background image card section---------------------- */

.cardbackground {
    padding: 30px;
    border-radius: 30px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
    margin-top: 150px;
    width: 550px;
    height: max-content;
    margin-left: 70px;
}

.backgroundrow {
    height: 600px;
    background-image: url(../img/Aboutus/cngtank.png);
    background-size: cover;

}

.cardbackground p {
    font-size: 22px;
}

@media (max-width:767px) {
    .backgroundrow {
        height: 700px;
        padding: 15px;
        margin: 0px;
        width: 100%;
        background-image: url(../img/Aboutus/cngtank.png);
        background-size: cover;
        overflow: hidden;
    }

    .cardbackground {
        width: 94%;
        margin: 3px;
        display: flex;
        justify-content: center;
        font-size: 18px;
        padding: 15px;
    }

    .cardbackground p {
        font-size: 17px;
    }

    .personimages {
        margin-top: 100px;
        width: 90%;
    }

    .abujatext {
        font-size: 20px;

    }

    .mapbackground {
        background-image: url(../img/Aboutus/mapbackground.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-color: rgba(0, 0, 0, 0.4);
        height: max-content;
        padding-top: 0px;
        position: relative;
    }

    .map-container {
        position: relative;
        margin-top: 0px;
        margin-left: 50px;
        margin-right: -0px;
        margin-bottom: 0px;
    }

}

@media (min-width:768px) and (max-width:991px) {

    .backgroundrow {
        height: max-content;
        margin-top: 0px;
        width: fit-content;
        padding-left: 0px;
        padding-right: 0px;
        background-image: url(../img/Aboutus/cngtank.png);
        background-size: cover;
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .cardbackground {
        margin-top: 50px;
        margin-bottom: 20px;
        margin-left: 110px;
    }
    .personimages {
        margin-top: 80px;
        height: 90%;
    }
}

@media (min-width:992px) {
    .personimages {
        margin-top: 40px;
        height: 580px;
    }
}
@media only screen and (min-width:1024px) and (max-width:1199px) {
    .cardbackground {
        margin-top: 50px;
        margin-bottom: 20px;
        margin-left: 20px;
    }
    .headdingourvalue{
        font-size: 50px;
    }
}


/* -----------------------------background image card section---------------------- */

/*------------------------------------- leaf background start------------------------------------- */
.headdingourvalue {
    text-align: center;
    font-weight: 600;
    margin-top: 40px;
    font-size: 45px;
}

.leafpararow1 {
    font-size: 19px;
    padding-top: 10px;
    padding-left: 80px;
    padding-right: 80px;
}

.leafpararow2 {
    font-size: 19px;
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;

}

.leafrow1 {
    margin-top: 50px;
}

.about-cards-title {
    margin-top: -35px;
    font-weight: bold;
}


.leafrow2 {
    margin-top: 60px;
    margin-bottom: 50px;
}

.leafimage {
    height: 230px;
    width: 230px;

}

@media (max-width:767px) {
    .leafpararow1 {
        padding-left: 30px;
        padding-right: 30px;
    }

    .leafimage {
        height: 150px;
        width: 150px;
    }

}

@media (min-width:768px) and (max-width:991px) {
    .leafpararow1 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .leafpararow2 {
        padding-left: 10px;
        padding-right: 10px;
    }
}

/*------------------------------------- leaf background end------------------------------------- */


/* -------------------------------------------map protion----------------------------------- */

.mapbackground {
    background-image: url(../img/Aboutus/mapbackground.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: max-content;
    padding-top: 70px;
    position: relative;
}

.overlay2 {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
}


.map-container {
    position: relative;
    margin-top: 60px;
    margin-bottom: 50px;

}

/* .map-container .flap-img {
    height: 80px;
    width: 80px;
}

.map-container .flap-img-lagos{
    height: 80px;
    width: 80px;
}
.map-container .flap-img-kaduna{
    height: 80px;
    width: 80px;
}
.map-container .flap-img-kwara{
    height: 80px;
    width: 80px;

}
.map-container .flap-img-kano {
    height: 80px;
    width: 80px;
}
.map-container .flap-img-sokoto {
    height: 80px;
    width: 80px;
}
.map-container .flap-img-maiduguri {
    height: 80px;
    width: 80px;
}
.map-container .flap-img-yola{
    height: 80px;
    width: 80px;
}
.map-container .flap-img-plateau{ 
    height: 80px;
    width: 80px;
} */

.map-container .abuja-map-img {
    width: 86%;
}

.map-container .lagos-map-img {
    width: 85%;
}

.map-container .kaduna-map-img {
    width: 85%;
}

.map-container .kwara-map-img {
    width: 85%;
}
.map-container .kano-map-img {
    width: 85%;
}


.map-container .sokoto-map-img {
    width: 84%;
}

.map-container .maiduguri-map-img {
    width: 84%;
}

.map-container .yola-map-img {
    width: 84%;
}

.map-container .plateau-map-img {
    width: 83%;
}



.flap-img {
    position: absolute;
    width: 80px;
    height: 80px;
    top: 46%;
    left: 34%;
    transform: translate(-50%, -50%);
}

.abujatext {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    font-size: xxx-large;
    font-weight: bolder;
    color: white;


}

.flap-img-kano {
    position: absolute;
    width: 80px;
    height: 80px;
    top: 18%;
    left: 44%;
    transform: translate(-50%, -50%);
}

.flap-img-kadura {
    position: absolute;
    width: 80px;
    height: 80px;
    top: 15%;
    left: 32%;
    transform: translate(-50%, -50%);
}

.flap-img-lagos {
    position: absolute;
    width: 80px;
    height: 80px;
    top: 68%;
    left: 11%;
    transform: translate(-50%, -50%);

}

.flap-img-kaduna {
    position: absolute;
    width: 80px;
    height: 80px;
    top: 33%;
    left: 39%;
    transform: translate(-50%, -50%);
}

.flap-img-kwara {
    position: absolute;
    width: 80px;
    height: 80px;
    top: 50%;
    left: 17%;
    transform: translate(-50%, -50%);

}

.flap-img-sokoto {
    position: absolute;
    width: 80px;
    height: 80px;
    top: 1%;
    left: 22%;
    transform: translate(-50%, -50%);

}

.flap-img-maiduguri {
    position: absolute;
    width: 80px;
    height: 80px;
    top: 22%;
    left: 73%;
    transform: translate(-50%, -50%);

}

.flap-img-yola {
    position: absolute;
    width: 80px;
    height: 80px;
    top: 42%;
    left: 70%;
    transform: translate(-50%, -50%);
}

.flap-img-plateau {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 46%;
    left: 51%;
    transform: translate(-50%, -50%);
}

.slick-slide img {
    height: 30%;
}

@media (max-width:767px) {
    .defaulttext {
        color: white;
        text-align: center;
        font-size: 21px;
        justify-content: center;
        margin-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        font-weight: bolder;
        height: max-content;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .defaulttext {
        color: white;
        text-align: center;
        justify-content: center;
        margin-top: 140px;
        font-size: 17px;
        font-weight: bolder;
    }
    
    .map-container .abuja-map-img {
        width: 87%;
        
    }

    .map-container .lagos-map-img {
        width: 87%;
    }

    .map-container .kaduna-map-img {
        width: 87%;
    }

    .map-container .kwara-map-img {
        width: 87%;
    }

    .map-container .kano-map-img {
        width: 86%;
    }

    .map-container .sokoto-map-img {
        width: 85%;
    }

    .map-container .maiduguri-map-img {
        width: 86%;
    }

    .map-container .yola-map-img {
        width: 86%;
    }

    .map-container .plateau-map-img {
        width: 86%;
    }
    .slick-next2{
        margin-top:50px;
        margin-right: 0px;
        height: 22px;
        width: 22px;
        
    }
    .slick-next2 i{
        font-size: 12px;
    }
    .slick-prev2{
        margin-top: 50px;
        margin-left: 0px;
        height: 22px;
        width: 22px;
    }
    .slick-prev2 i{
        font-size: 12px;
    }
}

@media (min-width:992px) {
    .defaulttext {
        color: white;
        text-align: center;
        justify-content: center;
        margin-top: 140px;
        padding-left: 110px;
        padding-right: 110px;
        font-weight: bolder;
      
    }
    .slick-next2{
        margin-top:50px;
        margin-right: 0px;
        height: 35px;
        width: 35px;
        
    }
    .slick-next2 i{
        font-size: 12px;
    }
    .slick-prev2{
        margin-top: 50px;
        margin-left: 0px;
        height: 35px;
        width: 35px;
    }
    .slick-prev2 i{
        font-size: 12px;
    }
}

@media (max-width:319px) {
    .map-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
    }

    .map-container .abuja-map-img {
        width: 90%;
    }

    .map-container .lagos-map-img {
        width: 90%;
    }

    .map-container .kaduna-map-img {
        width: 90%;
    }

    .map-container .kwara-map-img {
        width: 90%;
    }

    .map-container .kano-map-img {
        width: 90%;
    }

    .map-container .sokoto-map-img {
        width: 90%;
    }

    .map-container .maiduguri-map-img {
        width: 90%;
    }

    .map-container .yola-map-img {
        width: 90%;
    }

    .map-container .plateau-map-img {
        
        width: 90%;
    }
    .slick-next2{
        margin-top:280px;
        margin-right: 30px;
        width: 20px;
        height: 20px;
    }
    .slick-next2 i{
        font-size: 12px;
    }
    .slick-prev2{
        margin-top: 280px;
        margin-left: 30px;
        width: 20px;
        height: 20px;
    }
    .slick-prev2 i{
        font-size: 12px;
    }


}
@media (min-width:320px) and (max-width:411px){
    .map-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
        overflow-y: hidden;
    }

    .row{
        margin-top: 0px;
       }

    .map-container .abuja-map-img {
       width: 85%;
    }

    .map-container .lagos-map-img {
        width: 85%;
    }

    .map-container .kaduna-map-img {
        width: 85%;
    }

    .map-container .kwara-map-img {
        width: 85%;
    }

    .map-container .kano-map-img {
        width: 85%;
    }

    .map-container .sokoto-map-img {
        width: 85%;
    }

    .map-container .maiduguri-map-img {
        width: 85%;
    }

    .map-container .yola-map-img {
        width: 85%;
    }

    .map-container .plateau-map-img {
        width: 85%;
    }
    .slick-next2{
        margin-top:290px;
        margin-right: 48px;
        width: 30px;
        height: 30px;
    }
    .slick-next2 i{
        font-size: 13px;
    }
    .slick-prev2{
        margin-top: 290px;
        margin-left: 48px;
        width: 30px;
        height: 30px;
    }
    .slick-prev2 i{
        font-size: 13px;
    }
}
@media (min-width:412px) and (max-width:767px) {
    
    .slick-next2{
        margin-top:280px;
        margin-right: 48px;
        width: 30px;
        height: 30px;
    }
    .slick-next2 i{
        font-size: 12px;
    }
    .slick-prev2{
        margin-top: 280px;
        margin-left: 48px;
        width: 30px;
        height: 30px;
    }
    .slick-prev2 i{
        font-size: 12px;
    }

    
}



/* -------------------------------------------map protion end----------------------------------- */