/*------------------- Banner style Start ------------------*/
/* .service-main {
    overflow-x: hidden;
} */

.service-banner {
    width: 100%;
    height: 80vh;
    position: relative;
}

.service-banner .service-banner-image {
    width: 100%;
    height: 100%;
    transform: scaleX(-1);
    background-size: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.service-banner-content img {
    position: absolute;
    width: 52%;
    height: 335px;
    top: 40px;
    left: 150px;
    transform: scale(1);
}

@media (max-width:767px) {
    .service-main {
        overflow-x: hidden;
    }

    .service-banner {
        width: 100%;
        height: 30vh;
    }

    .service-banner-content img {
        width: 70%;
        height: 60%;
        top: 10px;
        left: 10px;
    }

}

@media (min-width:768px) and (max-width:991px) {
    .service-banner {
        width: 100%;
        height: 500px;
    }

    .service-banner .service-banner-image {
        width: 100%;
        height: 100%;
    }

    .service-banner-content img {
        width: 550px;
        height: 60%;
        top: 20px;
        left: 70px;
    }
}
@media screen and (min-width: 741px) and (min-height: 854px) {
   .service-banner{
    height: 30vh;
   }
   
   .service-banner-content img {
    width: 60%;
    height: 75%;
    top: 20px;
    left: 70px;
}
.vehicle-image{
    margin-left: 0px;
}
  }
  
@media (min-width:992px) and (max-width:1199px) {
    .service-banner {
        height: 50vh;
    }
    .service-banner-content img {
        width: 600px;
        height: 50%;
        top: 20px;
        left: 70px;
    }
}

/*-------------------- Banner style End ------------------*/

/*---------- Service First cards section Start --------*/
.first-section-cards-main {
    margin: 70px 0px 70px 0px;
    width: 100%;
    height: max-content;
}

.first-section-image-content {
    position: relative;
    /* margin: 40px 0px 60px 0px; */
    width: 100%;
    height: 100%;
}

.first-main-card {
    height: 100%;
    /* top: 100px;
    left: 135px; */
    /* position: absolute; */
    z-index: 1;
    margin-left: 122px;
}

.first-main-image-card {
    width: 100%;
    /* height: 95vh; */
}

.person-image {
    width: 85%;
    height: 100%;
    margin-left: 85px;
}


.service-first-title {
    font-size: 40px;
    font-weight: 700;
}

.service-first-title span {
    color: #C58854;
}

.mobile-view-show {
    display: none;
}


.vehicle-car-image {
    width: 85%;
    height: 100%;
    margin-left: 85px;
}



.first-card {
    padding: 5px;
    width: 620px;
    height: 110px;
    margin-top: 19px;
    line-height: 24px;
    /* padding-bottom: 30px; */
    background-color: #C58854;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.first-card::after {
    content: "";
    position: absolute;
    width: 60%;
    height: 20px;
    left: 0;
    bottom: -19px;
    background-color: #E8E8E8;
}

.first-card p {
    font-size: 20px;
    padding: 0px 24px;
    color: white;
    font-weight: 500;
}

.second-card {
    padding: 5px;
    width: 620px;
    height: 110px;
    margin-top: 19px;
    line-height: 24px;
    /* padding-bottom: 10px; */
    background-color: #FFFFFF;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

}

.second-card::after {
    content: "";
    position: absolute;
    width: 60%;
    height: 20px;
    left: 0;
    bottom: -19px;
    background-color: #E8E8E8;
}

.second-card p {
    font-size: 21px;
    padding: 0px 24px;
    color: #000000;
    font-weight: 500;
}

.last-card {
    padding: 5px;
    width: 620px;
    height: 110px;
    margin-top: 19px;
    line-height: 22px;
    /* padding-bottom: 10px; */
    background-color: #FFFFFF;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.last-card::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 20px;
    left: 0;
    bottom: -19px;
    background-color: #E8E8E8;
}

.last-card p {
    font-size: 21px;
    padding: 0px 24px;
    color: #000000;
    font-weight: 500;
}

@media (max-width:767px) {
    .first-section-cards-main {
        height: max-content;
        margin: 70px 0px 0px 0px;
    }

    .service-first-title {
        font-size: 20px;
        font-weight: 700;
    }

    .mobile-view-show {
        display: block;
        padding-top: 30px;
    }

    .mobile-view-hide {
        display: none;
    }

    .person-image {
        width: 100%;
        height: 100%;
        margin-left: 0px;
        background-size: cover;
    }

    .vehicle-car-image {
        width: 100%;
        height: 100%;
        margin-left: 0px;
        background-size: cover;
    }

    .first-section-image-content {
        padding: 0px 0px 0px 0px;
    }

    .first-main-card {
        width: 100%;
        margin-top: 10px;
        margin-left: 10px;
        margin-bottom: 30px;
        /* padding: 0px 5px; */
        position: static;
        z-index: 1;
    }

    .first-card {
        padding: 0px;
        width: 100%;
        height: 100px;
    }

    .first-card::after {
        width: 100%;
    }

    .first-card p {
        font-size: 15px;
        line-height: 17px;
        padding: 0px 5px;

    }

    .second-card {
        padding: 10px 0px 0px 0px;
        width: 100%;
        line-height: 20px;
        height: 90px;
    }

    .second-card::after {
        width: 100%;
    }

    .second-card p {
        font-size: 15px;
        line-height: 15px;
        padding: 0px 5px;

    }

    .last-card {
        padding: 5px;
        width: 100%;
        height: 100px;
    }

    .last-card::after {
        width: 100%;
    }

    .last-card p {
        font-size: 15px;
        line-height: 15px;
        padding: 0px 5px;

    }

}

@media (min-width:768px) and (max-width:991px) {
    .person-image {
        width: 85%;
        /* height: 65vh; */
        /* height: min(95vh, 600px); */
        background-size: cover;
    }

    .first-section-image-content {
        margin: 40px 0px 50px 0px;
    }

    .vehicle-car-image {
        width: 85%;
        /* height: 60vh; */
        height: min(95vh, 600px);
        background-size: cover;
    }


    .first-main-card {
        margin-left: 17px;
        padding-top: 0px;
    }

    .first-main-image-card {
        height: 90%;
    }

    .first-section-cards-main {
        height: max-content;
        margin: 70px 0px 70px 0px;
    }

    .first-card {
        padding-top: 10px;
        width: 400px;
        line-height: 20px;
        height: 87px;
    }

    .first-card::after {
        width: 70%;
    }

    .first-card p {
        font-size: 13px;
    }

    .second-card {
        padding: 5px;
        width: 400px;
        line-height: 20px;
        height: 85px;
    }

    .second-card::after {
        width: 70%;
    }

    .second-card p {
        font-size: 13px;
    }

    .last-card {
        padding: 5px;
        width: 400px;
        line-height: 20px;
        height: 80px;
    }

    .last-card::after {
        width: 100%;
    }

    .last-card p {
        font-size: 13px;
    }
}

/* @media (min-width:1024px) and (max-width:1400px) {
    .second-cards-main {
        height: 135vh;
    }

    .third-cards-main {
        height: 125vh;
    }

    .fourth-cards-main {
        height: 125vh;
    }

    .fifth-card-section-main {
        height: 145vh;
    }

    .sixth-cards-main {
        height: 135vh;
    }

    .seventh-cards-main {
        height: 135vh;

    }

    .vehicle2-image {
        margin-left: 20px;
    }


} */



/*---------- Service First cards section End -------------*/

/*---------- Service image Title Section Start---------- */

.kit-content {
    padding: 0px 250px;
    text-align: center;
    font-weight: 800;
}

.cards-adjust {
    width: 230px;
    height: 200px;
}

.card-title {
    font-size: 21px;
    font-weight: 800;
}

.service-kit-images-main {
    margin: 50px 90px 50px 180px;
}

.kit-images {
    width: 180px;
    height: 100px;
}

.kit-images-rotate {
    width: 170px;
    height: 70px;
}

.Solenoid-Valve-image {
    width: 140px;
    height: 120px;
}

.cng-filter-image {
    width: 140px;
    height: 120px;
}

.Electronic-Control-Unit-image {
    width: 200px;
    height: 130px;
}

.Coolant-Hose-image {
    width: 200px;
    height: 100px;
}

.Gas-Hose-image {
    width: 150px;
    height: 120px;
}

.Vacuum-Hose-image {
    width: 140px;
    height: 140px;
}

.Stainless-steel-Tube-image {
    width: 130px;
    height: 140px;
}

.Filling-Valve-image {
    width: 130px;
    height: 140px;
}

.Hardware-Packet-image {
    width: 130px;
    height: 130px;
}

.Wiring-Harness-image {
    width: 180px;
    height: 120px;
}

.Pressure-Gauge-image {
    width: 190px;
    height: 190px;
}

.Filling-Receptacle-images {
    width: 150px;
    height: 190px;
}

@media (max-width:767px) {
    .image-card {
        max-width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .kit-content {
        padding: 0px 0px;
        font-size: 15px;
    }

    .cards-adjust {
        width: 48%;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: none;
        margin: 0 1%;
    }

    .service-kit-images-main {
        margin: 0px 0px 30px 0px;
    }

    .kit-images {
        width: 100px;
        height: 70px;
    }

    .kit-images-rotate {
        width: 100px;
        height: 60px;
    }

    .Solenoid-Valve-image {
        width: 100px;
        height: 80px;
    }

    .cng-filter-image {
        width: 100px;
        height: 80px;
    }

    .Electronic-Control-Unit-image {
        width: 100px;
        height: 80px;
    }

    .Coolant-Hose-image {
        width: 100px;
        height: 80px;
    }

    .Gas-Hose-image {
        width: 100px;
        height: 70px;
    }

    .Vacuum-Hose-image {
        width: 100px;
        height: 90px;
    }

    .Stainless-steel-Tube-image {
        width: 100px;
        height: 80px;
    }

    .Filling-Valve-image {
        width: 100px;
        height: 90px;
    }

    .Hardware-Packet-image {
        width: 100px;
        height: 80px;
    }

    .Wiring-Harness-image {
        width: 100px;
        height: 80px;
    }

    .Pressure-Gauge-image {
        width: 100px;
        height: 100px;
    }

    .Filling-Receptacle-images {
        width: 100px;
        height: 100px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .kit-content {
        padding: 0px 0px;
        font-size: 20px;
    }

    .service-kit-images-main {
        margin: 0px 0px 30px 0px;
    }

    .tab-card-response {
        padding-top: 125px;
    }
}

@media only screen and (min-width:1024px) and (max-width:1199px) {
    .kit-content {
        padding: 0px 80px;
    }

    .service-kit-images-main {
        margin: 0px 0px 30px 0px;
    }


}

/*----------- Service image Title Section End------------- */

/*---------- Service Second Cards Section Start---------- */

.service-second-title {
    font-size: 40px;
    font-weight: 700;
}

.service-second-title span {
    color: #C58854;
}

.second-cards-main {
    position: relative;
    width: 100%;
    height: max-content;
    margin: 70px 0px 50px 0px;

}

.second-section-image-content {
    position: relative;
    width: 100%;
    height: 100%;
}

.second-main-card {
    height: 100%;
    /* position: absolute; */
    /* right: 100px; */
    margin-left: -350px;

}

.second-section-image-main {
    width: 100%;
    height: 100%;
    margin-left: 20px;
    /* z-index: -1; */
}

.vehicle-image {
    width: 85%;
    height: 100%;
    margin-left: 85px;
}


.second-first-card {
    padding: 5px;
    width: 620px;
    height: 100px;
    margin-top: 19px;
    line-height: 25px;
    background-color: #C58854;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.second-first-card::after {
    content: "";
    position: absolute;
    width: 21.9rem;
    height: 20px;
    left: 269px;
    bottom: -20px;
    background-color: #E8E8E8;
}

.second-first-card p {
    font-size: 21px;
    padding: 0px 24px;
    color: white;
    font-weight: 500;
}

.second-second-card {
    padding: 5px;
    width: 620px;
    height: 100px;
    margin-top: 19px;
    line-height: 24px;
    background-color: #FFFFFF;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.second-second-card::after {
    content: "";
    position: absolute;
    width: 21.9rem;
    height: 20px;
    left: 269px;
    bottom: -19px;
    background-color: #E8E8E8;
}

.second-second-card p {
    font-size: 20px;
    padding: 0px 24px;
    color: #000000;
    font-weight: 500;
}

.second-last-card {
    padding: 5px;
    width: 620px;
    height: 100px;
    margin-top: 19px;
    line-height: 22px;
    background-color: #FFFFFF;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.second-last-card::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 20px;
    left: 0;
    bottom: -19px;
    background-color: #E8E8E8;
}

.second-last-card p {
    font-size: 21px;
    padding: 0px 24px;
    color: #000000;
    font-weight: 500;
}



@media (max-width:767px) {
    .service-second-title {
        font-size: 23px;
        font-weight: 700;
    }

    .second-section-image-main {
        margin-left: 0px;
        /* z-index: -1; */
    }

    .second-cards-main {
        margin: 70px 0px 50px 0px;
        height: max-content;
    }

    .vehicle-image {
        width: 100%;
        height: 100%;
        margin-left: 0px;
        background-size: cover;
    }

    .second-section-image-content {
        padding: 0px 0px 30px 0px;
    }

    .second-main-card {
        width: 100%;
        padding-top: 10px;
        margin-left: 10px;
        margin-bottom: 30px;
        /* padding: 0px 5px; */
        position: static;
        z-index: 1;
    }

    .second-first-card {
        padding: 0px;
        width: 100%;
        height: 100px;
    }

    .second-first-card::after {
        width: 100%;
        left: 0px;
    }

    .second-first-card p {
        font-size: 15px;
        line-height: 17px;
        padding: 0px 5px;
    }

    .second-second-card {
        padding: 10px 0px 0px 0px;
        width: 100%;
        line-height: 20px;
        height: 90px;

    }

    .second-second-card::after {
        width: 100%;
        left: 0px;
    }

    .second-second-card p {
        font-size: 15px;
        line-height: 15px;
        padding: 0px 5px;
    }

    .second-last-card {
        padding: 5px;
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .second-last-card::after {
        width: 100%;
    }

    .second-last-card p {
        font-size: 15px;
        line-height: 15px;
        padding: 0px 5px;
    }

}


@media (min-width:768px) and (max-width:991px) {
    .second-cards-main {
        height: max-content;
        margin: 70px 0px 50px 0px;

    }

    .second-section-image-content {
        margin: 0px 0px 80px 0px;
    }

    .second-section-image-main {
        height: 90%;
    }

    .second-main-card {
        /* margin-right: -180px; */
        margin-left: -240px;
        padding-top: 0px;
        /* overflow: hidden; */
    }

    .second-first-card {
        padding-top: 10px;
        width: 420px;
        line-height: 20px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .second-first-card::after {
        width: 61%;
        left: 164px;
    }

    .vehicle-image {
        margin-left: 0px;
    }

    .second-first-card p {
        font-size: 13px;
    }

    .second-second-card {
        padding: 5px;
        width: 420px;
        line-height: 20px;
        height: 85px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .second-second-card::after {
        width: 61%;
        left: 165px;
    }

    .second-second-card p {
        font-size: 13px;
    }

    .second-last-card {
        padding: 5px;
        width: 420px;
        line-height: 20px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .second-last-card::after {
        width: 100%;
    }

    .second-last-card p {
        font-size: 13px;
    }
}

/*---------- Service second Cards Section End---------- */

/*---------- Service third Cards Section Start---------- */

.third-cards-main {
    margin: 130px 0px 70px 0px;
    height: max-content;
    width: 100%;
}

.vehicle-mobile-view-visible {
    display: none;
}

@media (max-width:767px) {
    .third-cards-main {
        margin: 0px 0px 50px 0px;
        height: max-content;
    }

    .vehicle-mobile-view-visible {
        display: block;
        padding-top: 10px;
    }

    .vehicle-mobile-view-hide {
        display: none;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .third-cards-main {
        margin: 50px 0px 50px 0px;
        height: max-content;
    }

}

/*---------- Service third Cards Section End---------- */

/*---------- Service fourth Cards Section Start---------- */

.fourth-cards-main {

    position: relative;
    width: 100%;
    height: max-content;
    margin: 100px 0px 120px 0px;

}



.second-last-card-logo-color {
    padding: 5px;
    width: 620px;
    height: 100px;
    margin-top: 19px;
    line-height: 22px;

    background-color: #C58854;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.second-last-card-logo-color::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 20px;
    left: 0;
    bottom: -19px;
    background-color: #E8E8E8;
}

.second-last-card-logo-color p {
    font-size: 21px;
    padding: 0px 24px;
    color: #FFFFFF;
    font-weight: 500;
}

.vehicle2-image {
    width: 85%;
    height: 92%;
    margin-left: 85px;
}

@media (max-width:767px) {

    .fourth-cards-main {
        margin: 0px 0px 20px 0px;
        height: max-content;
    }

    .vehicle2-image {
        width: 100%;
        height: 100%;
        margin-left: 0px;
        background-size: cover;
    }

    .second-last-card-logo-color {
        padding: 5px;
        width: 100%;
        height: 100px;
    }

    .second-last-card-logo-color::after {
        width: 100%;
    }

    .second-last-card-logo-color p {
        font-size: 15px;
        line-height: 15px;
        padding: 0px 5px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .fourth-cards-main {
        margin: 90px 0px 0px 0px;
        height: max-content;
    }

    .vehicle2-image {
        margin-left: 0px;
    }


    .second-last-card-logo-color {
        padding: 5px;
        width: 420px;
        line-height: 20px;
        height: 80px;
    }

    .ssecond-last-card-logo-color::after {
        width: 100%;
    }

    .second-last-card-logo-color p {
        font-size: 13px;
    }
}


/*---------- Service fourth Cards Section End---------- */

/*---------- Service fifth Cards Section Start---------- */

.fifth-card-section-main {
    height: max-content;
    margin: 100px 0px 100px 0px;
}

.cng-station-mobile-view-visible {
    display: none;
}

.cng-stations-image {
    width: 85%;
    height: 85%;
    margin-left: 85px;
}

.fifth-last-before-card {
    padding: 5px;
    width: 620px;
    height: 100px;
    margin-top: 19px;
    line-height: 25px;
    background-color: #C58854;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fifth-last-before-card::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 20px;
    left: 0;
    bottom: -19px;
    background-color: #E8E8E8;
}

.fifth-last-before-card p {
    font-size: 21px;
    padding: 0px 24px;
    color: white;
    font-weight: 500;
}

.fifth-last-card {
    padding: 5px;
    width: 620px;
    height: 90px;
    margin-top: 19px;
    line-height: 22px;
    background-color: #FFFFFF;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fifth-last-card::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 10px;
    left: 0;
    bottom: -9px;
    background-color: #E8E8E8;
}

.fifth-last-card p {
    font-size: 21px;
    padding: 0px 24px;
    color: #000000;
    font-weight: 500;
}


@media (max-width:767px) {
    .fifth-card-section-main {
        margin: 0px 0px 0px 0px;
        height: max-content;
    }

    .cng-station-mobile-view-visible {
        display: block;
    }

    .cng-station-mobile-view-hide {
        display: none;
    }

    .cng-stations-image {
        width: 100%;
        height: 100%;
        margin-left: 0px;
        background-size: cover;
    }

    .fifth-last-before-card {
        padding: 0px;
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .fifth-last-before-card::after {
        width: 100%;
    }

    .fifth-last-before-card p {
        font-size: 15px;
        line-height: 17px;
        padding: 0px 5px;
    }

    .fifth-last-card {
        padding: 5px;
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .fifth-last-card::after {
        width: 100%;
    }

    .fifth-last-card p {
        font-size: 15px;
        line-height: 15px;
        padding: 0px 5px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .fifth-card-section-main {
        margin: 0px 0px 0px 0px;
        height: max-content;
    }

    .cng-stations-image {
        width: 85%;
        height: 85%;
        background-size: cover;
    }

    .fifth-last-before-card {
        padding-top: 10px;
        width: 400px;
        line-height: 20px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .fifth-last-before-card::after {
        width: 70%;
    }

    .fifth-last-before-card p {
        font-size: 13px;
    }

    .fifth-last-card {
        padding: 5px;
        width: 400px;
        line-height: 20px;
        height: 85px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .fifth-last-card::after {
        width: 100%;
    }

    .fifth-last-card p {
        font-size: 13px;
    }
}


/*---------- Service fifth Cards Section End---------- */

/*---------- Service sixth Cards Section Start---------- */
.sixth-cards-main {
    width: 100%;
    margin: 100px 0px 100px 0px;
    height: max-content;
}


.sixth-last-card {
    padding: 5px;
    width: 620px;
    height: 100px;
    margin-top: 19px;
    line-height: 22px;
    background-color: #FFFFFF;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sixth-last-card::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 20px;
    left: 0;
    bottom: -19px;
    background-color: #E8E8E8;
}

.sixth-last-card p {
    font-size: 21px;
    padding: 0px 24px;
    color: #000000;
    font-weight: 500;
}


@media (max-width:767px) {
    .sixth-cards-main {
        margin: 40px 0px 20px 0px;
        height: max-content;
    }

    .virtual-pipeline-image {
        width: 100%;
        height: 100%;
        margin-left: 0px;
        background-size: cover;
    }

    .sixth-last-card {
        padding: 5px;
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .sixth-last-card::after {
        width: 100%;
    }

    .sixth-last-card p {
        font-size: 15px;
        line-height: 15px;
        padding: 0px 5px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .sixth-cards-main {
        margin: 120px 0px 30px 0px;
        height: max-content;
    }

    .virtual-pipeline-image {
        width: 85%;
        /* height: 60vh; */
        height: min(95vh, 600px);
        margin-left: 20px;
        background-size: cover;
    }

    .sixth-last-card {
        padding: 5px;
        width: 410px;
        line-height: 20px;
        height: 85px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .sixth-last-card::after {
        width: 100%;
    }

    .sixth-last-card p {
        font-size: 13px;
    }
}




/*---------- Service sixth Cards Section End---------- */

/*---------- Service seventh Cards Section End---------- */
.program-training-mobile-view-visible {
    display: none;
}

.program-traning-image {
    width: 85%;
    height: 90vh;
    margin-left: 85px;
}

.seventh-cards-main {
    width: 100%;
    height: 100vh;
    margin: 80px 0px 100px 0px;
}


@media (max-width:767px) {
    .seventh-cards-main {
        margin: 0px 0px 50px 0px;
        height: max-content;
    }

    .program-training-mobile-view-visible {
        display: block;
    }

    .program-training-mobile-view-hide {
        display: none;
    }

    .program-traning-image {
        width: 100%;
        height: 100%;
        margin-left: 0px;
        background-size: cover;
    }

}

@media (min-width:768px) and (max-width:991px) {
    .seventh-cards-main {
        margin: 0px 0px 25px 0px;
        height: max-content;
    }

    .program-traning-image {
        width: 85%;
        height: 90%;
    }
}


/* --------------addition css------------------ */


@media (min-device-width:992px) and (max-device-width:1199px) {

    .first-section-cards-main {
        height: max-content;
    }

    .first-section-image-content {
        margin: 50px 0px 50px 0px;
    }

    .first-main-card {
        margin-left: 72px;
    }

    .first-main-image-card {
        height: 90%;
    }

    .person-image {
        height: 100%;
    }

    .second-cards-main {
        height: max-content;
    }

    .third-cards-main {
        height: max-content;
    }

    .fourth-cards-main {
        height: max-content;
    }

    .fifth-card-section-main {
        height: max-content;

    }

    .cng-stations-image {
        height: 85%;
    }

    .sixth-cards-main {
        height: 850px;
    }

    .seventh-cards-main {
        height: max-content;
    }

    .second-section-image-content {
        width: 95%;
    }

    .second-section-image-main {
        margin-left: 0px;
        height: 90%;
    }

    .program-traning-image {
        width: 85%;
        height: 99%;
    }

    .vehicle-image {
        margin-left: 50px;
    }

    .vehicle2-image {
        margin-left: 50px;
    }
}

/* @media only screen and(min-device-width:1024px) and (max-device-width:1199px) {

    .first-section-cards-main {
        height: 100vh;
    }

} */