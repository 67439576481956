/***-------------- Navbar Section Start----------- ***/

.navbar .navbar-brand,
.navbar a.btn {
    height: 100px
}

.navbar-toggler {
    margin-right: 15px;
    border: none;
    color:#C68955;
    font-size: 1.85rem;
}

.nav-toggle{
    width: 30px;
}
.navbar-toggler:focus {
    /* outline: none; */
    /* border: none; */
    box-shadow: none;
}

.navbar .navbar-nav .nav-link {
    margin-right: 40px;
    font-weight: 600;
    padding: 20px 0;
    color: black;
    font-weight: 500;
    text-transform: uppercase;
    outline: none;
}

.nav-item {
    font-weight: bold;
    margin-right: 10px;
    font-size: 17px;
    font-family: 'Inter', sans-serif;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
    color: #C68955;
}

.navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
}

@media (max-width: 319px) {
    .navbar-left {
        margin-left: 10px;
    }

    .navbar-logo {
        width: 120px;
    }

    .navbar .navbar-nav {
        width: 100%;
        position: absolute;
        background-color: #fff;
        z-index: 8000;
    }


}

@media (min-width: 320px) and (max-width:411px) {
    .navbar-left {
        margin-left: 20px;
    }

    .navbar-logo {
        width: 180px;
    }

    .navbar .navbar-nav {
        width: 100%;
        position: absolute;
        background-color: #fff;
        z-index: 8000;
    }
  
}

@media (min-width: 412px) and (max-width:767px) {
    .navbar-left {
        margin-left: 20px;
    }

    .navbar-logo {
        width: 180px;
    }

    .navbar .navbar-nav {
        width: 100%;
        position: absolute;
        background-color: #fff;
        z-index: 8000;
    }
 
}

@media (min-width: 768px) and (max-width:991px) {
    .navbar-logo {
        width: 180px;
    }

    .navbar .navbar-nav {
        width: 100%;
        position: absolute;
        background-color: #fff;
        z-index: 8000;
    }
}

@media (max-width: 991px) {
    .navbar .navbar-nav .nav-link {
        margin-right: 0;
        padding: 10px 0;
    }

    .navbar .navbar-nav {
        border-top: 1px solid #EEEEEE;
    }

    .navbar-left {
        margin-left: 30px;
    }


}

@media (min-width: 992px) {
    .navbar-logo {
        width: 180px;
    }

    .navbar .nav-item .dropdown-menu {
        display: block;
        border: none;
        margin-top: 0;
        top: 150%;
        opacity: 0;
        visibility: hidden;
        transition: .5s;
    }

    .navbar .nav-item:hover .dropdown-menu {
        top: 100%;
        visibility: visible;
        transition: .5s;
        opacity: 1;
    }

    .sticky-desktop {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1000;
        background-color: #fff;
        box-shadow: 0 8px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
        transition: box-shadow 0.3s ease;
    }
}

.hi {
    background-color: brown;
}

.text-nav {
    padding-right: 40px;
}

.text-nav a {
    font-family: 'Inter', sans-serif;
}

.getbutton {
    height: 40px;
    margin-top: 15px;

}

/***-------------- Navbar Section End----------- ***/