html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden !important;
}

h2,
h3,
h4,
h5,
h6,
span {
  font-family: 'Montserrat Alternates', sans-serif;
}

h1 {
  font-family: 'Futura PT', sans-serif;
}

p {
  font-family: 'Genos';
  font-size: 25px;
  font-weight: 300;
}

button {
  font-family: "Roboto";
}


 /* ---------Cookiee Section Start---------- */
.CookieConsent {
  position: relative;
  height: 120px;
  font-size: 16px;
  padding: 15px 30px 0px 20px;
  justify-content: center;
  text-align: left;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 100px;
  position: fixed;
  width: 85%;
  border: 2px solid grey;
  border-radius: 8px;
  z-index: 999;
  bottom: 0;
}

#rcc-confirm-button {
  position: absolute;
  bottom: 0px;
  right: 120px;
  background: black;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  color: white;
  cursor: pointer;
  flex: 0 0 auto;
  padding: 5px 10px;
  margin: 15px;
  font-size: 15px;
  width: 80px;
  border-radius: 2px;
  height: 30px;
  font-weight: bold;
  transition:transform border 0.5s;
}

#rcc-decline-button {
  position: absolute;
  bottom: 0px;
  right: 20px;
  font-size: 15px;
  font-weight: bold;
  background: white;
  border: 0px;
  border-radius: 2px;
  box-shadow: none;
  color: black;
  cursor: pointer;
  flex: 0 0 auto;
  width: 80px;
  height: 30px;
  padding: 5px 10px;
  margin: 15px;
  transition: transform 0.5s, border 0.5s, color 0.5s;
}

#rcc-confirm-button:hover {
  border: 1px solid white;
}

#rcc-decline-button:hover {
  border: 2px solid black;
}

@media (max-width: 767px) {
  .CookieConsent {
    height: 160px;
    font-size: 11px;
    padding: 5px;
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    left: 20px;
    width: 90%;
    bottom: 10px;
  }

  #rcc-confirm-button {
    width: 70px;
    right: 85px;
    height: 30px;
    bottom: -10px;
  }
  #rcc-decline-button {
    width: 70px;
    height: 30px;
    right: 0px;
    bottom: -10px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .CookieConsent {
    height: 120px;
    font-size: 13px;
    /* padding: 0px 5px 0px 0px; */
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    left: 50px;
    width: 85%;
    bottom: 0px;
  }

  #rcc-confirm-button {
    width: 70px;
    right: 100px;
    height: 30px;
    bottom: -5px;
  }
  #rcc-decline-button {
    width: 70px;
    right: 20px;
    height: 30px;
    bottom: -5px;
  }

}
 /* ---------Cookiee Section End---------- */

 /* ---------Loading Style Start---------- */

 /* .loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -90000;
  background-color: rgba(0, 0, 0, 0.5);
} */

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.1); 
  z-index: 20000;
}

.loading-img{
  position: absolute;
  top: 41%;
  width: 130px;
}


 .loader {
  position: fixed;
  top: 30%;
  left: 50%;
  margin-left: -100px; 
  transform: translate(-50%,-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 20px solid #EAF0F6;
  border-radius: 50%;
  border-top: 20px solid #FF7A59;
  width: 200px;
  height: 200px;
  background: transparent;
  animation: spinner 4s linear infinite;
}


@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
 /* ---------Loading Style End---------- */


  /* ---------Not Found Style STart---------- */

 .not-found {
  text-align: center;
  padding: 50px;
  margin-top: 50px;
}

.not-found h1 {
  font-size: 80px;
  font-weight: 700;
  color: #d6320d;
}

.not-found p {
  color: #555;
  font-size: 30px;
  font-weight: 500;
}

.not-found button {
  text-decoration: none;
  color: white;
  background-color: black;
  width: 200px;
  height: 60px;
  font-weight: 500;
  transition: background-color 0.3s ease; 
}

.not-found button:hover {
  background-color: #C68955; 
  color: white; 
}




/* ----------------Loader------------------------- */
@media (max-width:767px) {
  .loader {
    top: 31%;
  }
}

@media (min-width:768px) and (max-width:991px) {
  .loader {
    top: 34%;
  }
}

@media (min-width:992px) and (max-width:1199px) {
  .loader {
    top: 35%;
  }
}

/* ---------Not Found Style End---------- */
