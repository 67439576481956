.slick-prev1 {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  background-color: #c68955;
  border-radius: 50%;
  padding: 2px;
  height: 50px;
  width: 50px;
  z-index: 2;
  font-size: 20px;
  line-height: 25px;
  cursor: pointer;
}

.cardname input[type="number"]::-webkit-inner-spin-button,
.cardname input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cardname input[type="number"] {
  -moz-appearance: textfield;
}

.cardname input,
.cardname1 textarea {
  text-align: center;
}

.slick-prev1 i {
  color: white;
  font-size: 20px;
  font-weight: 900;
}

.slick-prev1:hover {
  background-color: white;
  border: 3px solid #c68955;
}

.slick-prev1:hover i {
  color: #c68955;
}

.slick-next1 {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  background-color: #c68955;
  border-radius: 50%;
  padding: 2px;
  height: 50px;
  width: 50px;
  font-size: 20px;
  line-height: 25px;
  cursor: pointer;
}

.slick-next1 i {
  color: white;
  font-size: 20px;
  font-weight: 900;
}

.slick-next1:hover {
  background-color: white;
  border: 3px solid #c68955;
}

.slick-next1:hover i {
  color: #c68955;
}
@media (min-width: 1200px) {
  .slider-padding {
    padding: 0px 50px 50px 50px;
  }
}

@media (max-width: 319px) {
  .slick-prev1 {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    border-radius: 50%;
    padding: 2px;
    height: 30px;
    width: 30px;
    font-size: 20px;
    line-height: 20px;
    cursor: pointer;
  }

  .slick-prev1 i {
    font-size: 18px;
    font-weight: 900;
  }

  .slick-next1 {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 38px;
    border-radius: 50%;
    padding: 2px;
    height: 30px;
    width: 30px;
    font-size: 20px;
    line-height: 20px;
    cursor: pointer;
  }

  .slick-next1 i {
    font-size: 18px;
    font-weight: 900;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .slick-prev1 {
    cursor: pointer;
    position: absolute;
    top: 30%;
    transform: translateY(-50%);
    left: 10px;
    border-radius: 50%;
    padding: 2px;
    height: 30px;
    width: 30px;
    font-size: 20px;
    line-height: 20px;
    cursor: pointer;
  }

  .slick-prev1 i {
    font-size: 18px;
    font-weight: 900;
  }

  .slick-next1 {
    cursor: pointer;
    position: absolute;
    top: 30%;
    transform: translateY(-50%);
    right: 38px;
    border-radius: 50%;
    padding: 2px;
    height: 30px;
    width: 30px;
    font-size: 20px;
    line-height: 20px;
    cursor: pointer;
  }

  .slick-next1 i {
    font-size: 18px;
    font-weight: 900;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .slick-prev1 {
    left: 50px;
  }

  .slick-next1 {
    right: 50px;
  }
}

/* ---------------Social Media Fixed Start---------------*/
.social-media {
  position: fixed;
  top: 30%;
  right: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40vh;
  z-index: 100;
}

.social-media a {
  margin: 10px;
  text-decoration: none;
}

/* .social-media a i:hover {
    background-color: #C9784A;
    color: #ffffff;
} */

/* WhatsApp icons */
/* .whatsapp-icon {
    position: fixed;
    bottom: 30px;
    right: 40px;
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
}

.whatsapp-icon img {
    width: 50px;
}

.whatsapp-icon::after {
    content: "How can we help you?";
    position: absolute;
    top: 30%;
    right: 50px;
    transform: translateY(-50%);
    padding: 5px 10px;
    border-radius: 5px;
    color: black;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.whatsapp-icon:hover::after {
    opacity: 1;
} */

/* responsive */

@media (max-width: 767px) {
  .social-media a i {
    font-size: 19px;
    height: 35px;
    width: 35px;
    background-color: black;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    transition: background-color 0.3s, color 0.3s;
  }

  .social-media a i:hover {
    background-color: #c9784a;
    color: #ffffff;
  }

  .whatsapp-icon img {
    width: 25px;
  }

  .social-media {
    overflow-x: hidden;
    height: 40vh;
    top: 15%;
    right: 5px;
  }

  .whatsapp-icon {
    right: 15px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .social-media a i {
    font-size: 15px;
    height: 35x;
    width: 35px;
    background-color: black;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    transition: background-color 0.3s, color 0.3s;
  }

  .social-media {
    overflow-x: hidden;
    height: 40vh;
    top: 30%;
    right: 5px;
  }

  .social-media a i:hover {
    background-color: #c9784a;
    color: #ffffff;
  }

  .whatsapp-icon img {
    width: 35px;
  }
}

@media (min-width: 992px) {
  .social-media a i {
    font-size: 30px;
    height: 50px;
    width: 50px;
    background-color: black;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    transition: background-color 0.3s, color 0.3s;
  }

  .social-media a i:hover {
    background-color: #c9784a;
    color: #ffffff;
  }
}

/*--------------- Social Media Fixed End--------------*/

/* -----------------About Section Start ----------------*/

.gridcontent {
  margin-top: -6px;
}

.grid1 {
  height: 200px;
  width: 250px;
  margin-bottom: 10px;
}

.grid2 {
  height: 200px;
  width: 250px;
}

.grid3 {
  height: 410px;
  width: 260px;
  margin-left: 20px;
  object-fit: cover;
}

.titlecolor {
  color: #c68955;
}

.gridspace {
  margin-top: 65px;
}

/* .about-responsive-margin {
    padding: 0px 90px;
} */

.backimageforgrid {
  background-image: url("../../assets/img/Home/backpng1.png");
  background-position: 138% 40%;
  background-size: 130% 160%;
  /* background-repeat: no-repeat; */
  /* background-position: 130%;
    background-size:120%; */
}

.about-content1 {
  padding: 0px 145px 0px 60px;
  padding-top: 20px;
}

.about-content1 p {
  font-size: 23px;
}

@media (max-width: 319px) {
  .gridcontent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .about-content1 {
    padding: 0px 20px 0px 20px;
  }

  .about-content1 p {
    font-size: 17px;
  }

  .about-responsive-margin {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px 0px;
  }

  .grid1 {
    height: 200px;
    width: 250px;
    margin-bottom: 10px;
  }

  .grid2 {
    height: 200px;
    width: 250px;
  }

  .grid3 {
    height: 410px;
    object-fit: cover;
    width: 250px;
    margin-top: 10px;
    margin-left: 0px;
  }

  .gridspace {
    margin-top: 0px;
  }
}

@media (min-width: 320px) and (max-width: 411px) {
  .about-responsive-margin {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px 0px 20px;
  }

  .grid1 {
    height: 200px;
    width: 300px;
    margin-bottom: 10px;
  }

  .grid2 {
    height: 200px;
    width: 300px;
  }

  .grid3 {
    height: 410px;
    object-fit: cover;
    width: 300px;
    margin-top: 10px;
    margin-left: 0px;
  }

  .gridspace {
    margin-top: 0px;
  }

  .about-content1 {
    padding: 0px 20px;
    margin-right: 10px;
  }

  .about-content1 p {
    font-size: 18px;
  }
}

@media (min-width: 412px) and (max-width: 767px) {
  .about-responsive-margin {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px 30px;
  }

  .grid1 {
    height: 200px;
    width: 300px;
    margin-bottom: 10px;
  }

  .grid2 {
    height: 200px;
    width: 300px;
  }

  .grid3 {
    height: 410px;
    object-fit: cover;
    width: 300px;
    margin-top: 10px;
    margin-left: 0px;
  }

  .gridspace {
    margin-top: 0px;
  }

  .about-content1 {
    padding: 0px;
    margin-right: 10px;
  }

  .about-content1 p {
    font-size: 18px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .tablet-view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (mix-width: 768px) and (max-width: 991px) {
  .about-responsive-margin {
    padding: 0px 0px;
  }

  .gridspace {
    margin-top: 70px;
  }

  .about-content1 {
    padding: 0px 105px 0px 50px;
  }
}

@media (min-width: 992px) {
  .about-responsive-margin {
    padding: 0px 60px;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
  .about-responsive-margin {
    padding: 0px 20px;
  }

  .grid1,
  .grid2 {
    width: 230px;
  }

  .about-content1 {
    padding: 0px 50px 0px 120px;
  }
}

/* ------------------------About Section Start---------------- */

/*-------------------slider vehicles type--------------------- */
.backgroundcard {
  background-image: url("../../assets/img/Home/backpng2light.png");
  background-position: 250%;
  /* margin-right: 100px; */
  background-size: 78%;
  background-repeat: no-repeat;
  /* display: flex;
    justify-content: center; */
}

.slick-slide img {
  height: 70%;
  width: 90%;
}

.carousel-item {
  width: 100%;
  height: 500px;
  margin-top: 30px;
  /* padding: 0px 10px; */
  display: flex;
  justify-content: center;
}

.carousel-item p {
  width: 82%;
  display: flex;
  justify-content: center;
  font-size: 35px;
  font-weight: 700;
}

.slidertop {
  padding-top: 20px;
  width: 100%;
}

.card-image {
  width: 65%;
  border-radius: 10px;
  background-size: cover;
}

.slider-padding {
  width: 100%;
  height: 600px;
}

.centertext {
  width: 100%;
  font-size: 35px;
  padding: 100px 350px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.centertext p {
  font-size: 25px;
}

.carousel-item {
  padding: 0 10px;
  transition: transform 0.5s;
}

.slick-center {
  transform: scale(1.2);
}

@media (min-width: 992px) {
  .slider-padding {
    padding: 0px 100px 70px 100px;
  }

  .slider-card-main-padding {
    padding: 0px 0px;
  }

  .slick-slide img {
    height: 70%;
    width: 88%;
  }
}

@media (max-width: 319px) {
  .backgroundcard {
    margin-right: 0px;
    padding: 0px 10px;
  }

  .slider-card-main-padding {
    padding: 0px 0px;
  }

  .slick-slide img {
    height: 30%;
    width: 110%;
    margin-right: 30px;
  }

  .carousel-item {
    height: 600px;

    padding: 30px;
    margin-top: 0px;
  }

  .centertext {
    text-align: left;
    margin-top: -80px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .centertext p {
    font-size: 12px;
  }

  .carousel-item p {
    font-size: 15px;
    width: 100%;
    margin-top: 30px;
  }
}

@media (max-width: 319px) {
  .carousel-item {
    width: 100%;
    height: 350px;
    padding: 20px 30px 20px 20px;
    margin-top: 0px;
  }

  .slick-slide img {
    height: 45%;
    width: 100%;
    margin-right: 30px;
  }

  .slider-padding {
    height: 300px;
    padding: 0px 0px 0px 0px;
  }

  .slidertop {
    margin-top: -130px;
  }

  .slider-card-main-padding {
    padding: 20px 0px 0px 0px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .backgroundcard {
    margin-right: 0px;
    padding: 0px 20px;
  }

  .slider-card-main-padding {
    padding: 0px 0px;
  }

  .slick-slide img {
    height: 40%;
    width: 100%;
    margin-right: 30px;
  }

  .carousel-item {
    height: 600px;
    padding: 50px;
    margin-top: 0px;
  }

  .slider-padding {
    height: 400px;
  }

  .centertext {
    text-align: left;
    margin-top: -80px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .centertext p {
    font-size: 17px;
  }

  .carousel-item p {
    padding-left: 10px;
    font-size: 18px;
    width: 100%;
  }

  .slidertop {
    margin-top: -100px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .slider-padding {
    padding: 0px 0px;
  }

  .carousel-item {
    padding: 0px 50px 0px 150px;
  }

  .centertext {
    padding: 0px;
  }

  .centertext p {
    padding: 0px 105px 0px 60px;
    text-align: left;
  }

  .carousel-item p {
    width: 80%;
    justify-content: center;
    /* padding-left: 68px; */
    /* font-size: 35px;
        font-weight: 600; */
  }

  .slider-card-main-padding {
    padding: 50px 0px 0px 0px;
  }

  .slick-slide img {
    height: 70%;
    width: 80%;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1199px) {
  .centertext {
    padding: 100px 100px;
  }

  .slider-padding {
    padding: 0px 40px 50px 30px;
  }
  .slick-prev1 {
    margin-left: 20px;
  }
  .slick-next1 {
    margin-right: 30px;
  }
}

/* -------------------slider vehicles type end ------------------ */

/*---------------------------- contact us start--------------------- */

.contactus {
  background-image: url("../../assets/img/Home/contactus1.jpg");
  min-height: 750px;
  width: 100%;
  background-size: cover;
  padding: 50px;
  margin-right: 0px;
  position: relative;
}

.readytext {
  padding-top: 60px;
  padding-bottom: 20px;
  padding-left: 90px;
  color: #009846;
  font-size: xxx-large;
  font-weight: 900;
}

.images {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.imagecontent1 {
  height: 70px;
  width: 70px;
  padding: 10px;
  background-color: #009846;
  border-radius: 50%;
}

.imagecontent2 {
  height: 70px;
  width: 70px;
  padding: 10px;
  background-color: #009846;
  border-radius: 50%;
}

.imagecontent3 {
  height: 70px;
  width: 70px;
  padding: 10px;
  background-color: #009846;
  border-radius: 50%;
  margin-left: 0px;
}

.rowalign {
  padding-left: 50px;
}

.arrowimage {
  width: 60px;
}

.textimage {
  margin-left: 20px;
  margin-bottom: 80px;
}

.textimage1 {
  margin-left: 80px;
  margin-bottom: 30px;
  margin-top: 20px;
}

.textimage2 {
  margin-left: 70px;
  margin-top: 20px;
}

.textimage3 {
  margin-left: 15px;
  margin-top: 20px;
}

.textimage4 {
  margin-left: 20px;
  margin-top: 20px;
}

.textimage5 {
  margin-left: 100px;
  margin-top: 20px;
  margin-bottom: 50px;
}

.textimage6 {
  margin-left: 120px;
  margin-top: 20px;
  margin-bottom: 50px;
}

.textimage7 {
  margin-left: 58px;
  margin-top: 20px;
}

.semiline {
  margin-left: -450px;
}

.image {
  height: 70px;
  width: 70px;
  padding: 10px;
  background-color: #009846;
  border-radius: 50%;
}

.firstimg {
  height: 70px;
  width: 70px;
  padding: 10px;
  background-color: #009846;
  border-radius: 50%;
}

.semiline {
  margin-top: 25px;
  margin-left: -50px;
  height: 240px;
}

.newimg {
  /* margin-left: 20px; */
  margin-bottom: 0px;
}

.newimg2 {
  margin-left: -20px;
}

.cardinput {
  background-color: #009846;
  min-height: 300px;
  width: 100%;
  margin-top: -70px;
  border-radius: 25px;
  margin-left: -60px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-input::placeholder {
  font-size: 17px;
  text-align: center;
  color: #cfcbc9;
}

.cardname {
  margin-top: 10px;
  border-radius: 27px;
  width: 378px;
  padding: 10px;
  margin: 25px;
}

.cardname1 {
  margin-top: 10px;
  border-radius: 27px;
  padding: 10px;
  width: 378px;
  margin: 25px;
  height: 120px;
}

.buttoncontact {
  width: 150px;
  padding: 10px;
  font-size: 17px;
  font-weight: bold;
  background-color: black;
}

.buttonpadding {
  margin-bottom: 30px;
}

.contact-input:focus {
  border-bottom: 2px solid #000;
}

.contact-input:focus,
.contact-input:valid {
  border: none;
  outline: none;
}
.country-input::placeholder {
  font-size: 11px;
}
.country-input:focus {
  border: none;
  outline: none;
}
.country-input {
  width: 80px;
  margin: 5px;
  height: 40px;
  border: none;
  text-align: center;
}

.country-inputname {
  margin-top: 25px;
  margin-left: 25px;
  border-radius: 27px;
  width: 100px;
  height: 35px;
  padding: 0px;
}

.contact-input-mobile {
  margin-top: 10px;
  border-radius: 27px;
  width: 225px;
  padding: 5px;
  margin: 25px;
}
.contact-input-inputbox {
  border: none;
  text-align: center;
}
.contact-input-inputbox:focus {
  outline: none;
  border: none;
}

.cardname1 {
  margin-top: 10px;
  border-radius: 27px;
  padding: 10px;
  width: 378px;
  margin: 25px;
  height: 120px;
}

@media (max-width: 319px) {
  .newimg2 {
    margin-left: 0px;
  }

  .m-card-main {
    width: 40px;
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .m-newimg {
    width: 50px;
  }

  .mobile-responsive {
    display: flex;
    flex-direction: column;
  }

  .cardinput {
    margin-left: 0px;
    width: 110%;
    height: 350px;
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 20px;
    padding-top: 22px;
  }

  .semiline {
    display: none;
  }

  .overfolwtagt {
    overflow: hidden;
    overflow-x: hidden;
  }

  .contactus {
    min-height: 500px;
    background: linear-gradient(rgba(25, 31, 2, 0.822), rgba(63, 47, 26, 0.842)),
      linear-gradient(rgba(42, 39, 39, 0.144), rgba(39, 36, 36, 0.144)),
      url("../../assets/img/Home/contactus1.jpg");
    width: 100%;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    padding: 50px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .buttoncontact {
    width: 110px;
    line-height: 15px;
    margin-left: 20px;
    font-size: 17px;
    font-weight: bold;
    background-color: black;
  }

  .cardname {
    margin-top: 10px;
    border-radius: 7px;
    width: 150px;
    height: 30px;
    padding: 10px;
    margin: 10px;
  }

  .cardname1 {
    margin-top: 10px;
    border-radius: 7px;
    width: 150px;
    height: 50px;
    padding: 10px;
    margin: 10px;
  }

  .contact-input {
    width: 120px;
    height: 20px;
    margin-top: -10px;
    margin-left: 5px;
    /* line-height: 30px; */
  }

  .contact-input::placeholder {
    font-size: 13px;
    color: #cfcbc9;
  }

  .readytext {
    text-align: center;
    padding-top: 0px;
    padding-left: 0px;
    font-size: 25px;
  }
  .country-input {
    width: 35px;
    margin: 3px;
    height: 40px;
    border: none;
    text-align: center;
  }

  .country-inputname {
    margin-top: 25px;
    margin-left: 27px;
    border-radius: 7px;
    width: 50px;
    height: 35px;
    padding: 0px;
  }

  .contact-input-mobile {
    margin-top: 10px;
    border-radius: 7px;
    width: 100px;
    padding: 5px;
    margin: 25px;
  }
}

@media (min-width: 320px) and (max-width: 411px) {
  .newimg2 {
    margin-left: 0px;
  }

  .m-card-main {
    width: 40px;
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .m-newimg {
    width: 50px;
  }

  .mobile-responsive {
    display: flex;
    flex-direction: column;
  }

  .cardinput {
    margin-left: 0px;
    width: 100%;
    height: 350px;
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 20px;
    padding-top: 22px;
  }

  .about-content {
    padding: 20px;
  }

  .semiline {
    display: none;
  }

  .overfolwtagt {
    overflow: hidden;
    overflow-x: hidden;
  }

  .contactus {
    min-height: 500px;
    background: linear-gradient(rgba(25, 31, 2, 0.822), rgba(63, 47, 26, 0.842)),
      linear-gradient(rgba(42, 39, 39, 0.144), rgba(39, 36, 36, 0.144)),
      url("../../assets/img/Home/contactus1.jpg");
    width: 100%;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    padding: 50px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .contact-input {
    width: 180px;
    height: 28px;
    margin-top: -10px;
    margin-left: 5px;
    /* line-height: 30px; */
  }

  .contact-input::placeholder {
    font-size: 13px;
    color: #cfcbc9;
  }

  .buttoncontact {
    width: 110px;
    line-height: 15px;
    margin-left: 20px;
    font-size: 17px;
    font-weight: bold;
    background-color: black;
  }

  .cardname {
    margin-top: 10px;
    border-radius: 7px;
    width: 240px;
    height: 35px;
    padding: 10px;
    margin: 10px;
  }

  .cardname1 {
    margin-top: 10px;
    border-radius: 7px;
    width: 240px;
    height: 50px;
    padding: 10px;
    margin: 10px;
  }

  .readytext {
    text-align: center;
    padding-top: 0px;
    padding-left: 0px;
    font-size: 30px;
  }
  .country-input {
    width: 50px;
    margin: 3px;
    height: 40px;
    border: none;
    text-align: center;
  }

  .country-inputname {
    margin-top: 25px;
    margin-left: 27px;
    border-radius: 8px;
    width: 70px;
    height: 35px;
    padding: 0px;
  }

  .contact-input-mobile {
    margin-top: 10px;
    height: 35px;
    border-radius: 8px;
    width: 140px;
    padding: 5px;
    margin: 25px;
  }
}

@media (min-width: 412px) and (max-width: 767px) {
  .newimg2 {
    margin-left: 0px;
  }

  .m-card-main {
    width: 40px;
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .m-newimg {
    width: 50px;
  }

  .mobile-responsive {
    display: flex;
    flex-direction: column;
  }

  .cardinput {
    margin-left: 0px;
    width: 100%;
    height: 350px;
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 20px;
    padding-top: 22px;
  }

  .about-content {
    padding: 20px;
  }

  .semiline {
    display: none;
  }

  .overfolwtagt {
    overflow: hidden;
  }

  .contactus {
    min-height: 500px;
    background: linear-gradient(rgba(25, 31, 2, 0.822), rgba(63, 47, 26, 0.842)),
      linear-gradient(rgba(42, 39, 39, 0.144), rgba(39, 36, 36, 0.144)),
      url("../../assets/img/Home/contactus1.jpg");
    width: 100%;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    padding: 50px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .contact-input {
    width: 180px;
    height: 28px;
    margin-top: -10px;
    margin-left: 5px;
    /* line-height: 30px; */
  }

  .contact-input::placeholder {
    font-size: 13px;
    color: #cfcbc9;
  }

  .buttoncontact {
    width: 110px;
    line-height: 15px;
    margin-left: 20px;
    font-size: 17px;
    font-weight: bold;
    background-color: black;
  }

  .cardname {
    margin-top: 10px;
    border-radius: 7px;
    width: 230px;
    height: 35px;
    padding: 10px;
    margin: 10px;
  }

  .cardname1 {
    margin-top: 10px;
    border-radius: 7px;
    width: 230px;
    height: 50px;
    padding: 10px;
    margin: 10px;
  }

  .readytext {
    text-align: center;
    padding-top: 0px;
    padding-left: 0px;
    font-size: 40px;
  }

  .mobile-res-semiline {
    display: block;
    width: 40px;
    height: 95px;
    position: absolute;
    top: 166px;
    right: 15px;
  }
  .country-input {
    width: 40px;
    margin: 3px;
    height: 40px;
    border: none;
    text-align: center;
  }

  .country-inputname {
    margin-top: 25px;
    margin-left: 27px;
    border-radius: 8px;
    width: 60px;
    height: 35px;
    padding: 0px;
  }

  .contact-input-mobile {
    margin-top: 10px;
    border-radius: 8px;
    width: 140px;
    padding: 5px;
    margin: 25px;
  }
}

/* iphone 13 pro respoense */
@media only screen and (device-width: 1284px) and (device-height: 2778px) and (-webkit-device-pixel-ratio: 3) {
  .buttoncontact {
    width: max-content;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .newimg2 {
    margin-left: -40px;
  }

  .newimg {
    margin-bottom: 0px;
    margin-left: -24px;
  }

  .arrowimage {
    width: 60px;
    margin-left: -15px;
  }

  .cardinput {
    width: 75%;
    margin-left: 30px;
    margin-top: 20px;
  }

  .overfolwtagt {
    overflow: hidden;
  }

  .contactus {
    min-height: 500px;
    width: 100%;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    padding: 50px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .readytext {
    padding-top: 100px;
    padding-left: 50px;
  }

  .semiline {
    margin-top: 28px;
    margin-left: -30px;
    height: 235px;
  }

  .country-input::placeholder {
    font-size: 11px;
  }
  .country-input:focus {
    border: none;
    outline: none;
  }
  .country-input {
    width: 80px;
    margin: 6px;
    height: 40px;
    border: none;
    text-align: center;
  }

  .country-inputname {
    margin-top: 25px;
    margin-left: 20px;
    border-radius: 27px;
    width: 100px;
    height: 45px;
    padding: 0px;
  }

  .contact-input-mobile {
    margin-top: 10px;
    border-radius: 27px;
    width: 245px;
    height: 45px;
    padding: 5px;
    margin: 25px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .cardname {
    width: 230px;
  }

  .cardname1 {
    width: 230px;
    height: 50px;
  }
  .contact-input-mobile {
    width: 140px;
  }
  .country-inputname {
    width: 70px;
    margin-left: 27px;
  }
  .country-input {
    width: 40px;
  }
}
@media (min-width: 1280px) and (min-width: 800px) {
  .contact-input {
    width: 330px;
  }
  .cardname {
    width: max-content;
  }
  .cardname1 {
    width: max-content;
  }
  .country-input {
  }
}

/*----------- contact us for mobile respoense start------------- */
.m-contact {
  display: none;
}

.mobile-res-semiline {
  display: none;
}

@media (max-width: 767px) {
  .m-contact {
    display: block;
  }

  .d-contact {
    display: none;
  }

  .m-arrow {
    height: 20px;
    width: 40px;
    margin-top: -35px;
  }

  .m-contactus {
    height: 35px;
    width: 35px;
    padding: 3px;
    background-color: #009846;
    border-radius: 50%;
  }

  .m-vehicle {
    height: 35px;
    width: 35px;
    padding: 3px;
    background-color: #009846;
    border-radius: 50%;
  }

  .m-proofmaoney {
    height: 35px;
    width: 40px;
    padding: 3px;
    background-color: #009846;
    border-radius: 50%;
  }

  .m-schedule {
    height: 35px;
    width: 35px;
    padding: 3px;
    background-color: #009846;
    border-radius: 50%;
  }

  .mobile-res-semiline {
    display: block;
    width: 40px;
    height: 95px;
    position: absolute;
    top: 146px;
    right: 15px;
  }
}

/*----------- contact us for mobile respoense end------------- */

/*------------------- contact us end---------------------------- */

/* -------------------Video Card Section Start------------------- */

.cardadd1 {
  margin-top: 0px;
  margin-left: 20px;
  position: relative;
}

.cardsresponsive {
  padding-left: 500px;
  position: absolute;
  top: 0;
  right: 60px;
}

.secondcontentshow-title {
  padding: 0px 20px;
  font-size: 40px;
  color: #c68955;
  font-weight: 600;
}

.secondcontentshow-para {
  transition: opacity 0.3s ease-in-out;
}

.secondcontentshow-para1 {
  transition: opacity 0.3s ease-in-out;
}

.secondcontentshow-para {
  padding: 0px 20px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  right: 0;
  margin-right: 60px;
}

.secondcontentshow-para1 {
  padding: 0px 20px;
  margin-top: 50px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  right: 0;
  margin-right: 150px;
}

.cardadd2 {
  height: 300px;
  width: 280px;
  background-color: #c68955;
  border-radius: 20px;
}

.addbutton {
  text-align: right;
  padding-right: 30px;
  font-size: 27px;
  color: #f7f7f7;
  padding-top: 20px;
}

.addcontent {
  font-size: 42px;
  padding: 30px;
  line-height: 35px;
  color: white;
  margin-top: 80px;
}

.cardadd3 {
  display: none;
}

.cardadd4 {
  display: none;
}

@media (max-width: 319px) {
  .cardsresponsive {
    margin-right: 230px;
    padding-bottom: 300px;
  }

  .video {
    height: 116%;
    width: 100%;
    object-fit: cover;
  }

  .cardadd1 {
    display: none;
  }

  .cardadd3 {
    display: block;
    margin-left: 25px;
  }

  .cardadd2 {
    display: none;
  }

  .cardadd4 {
    display: block;
    margin-top: 10px;
    margin-left: 25px;
  }

  .secondcontentshow-title {
    padding: 0px 20px;
    font-size: 25px;
  }

  .secondcontentshow-para {
    padding: 0px 20px;
    font-size: 17px;
    font-weight: 600;
    margin-left: 10px;
  }

  .secondcontentshow-para1 {
    padding: 0px 20px;
    font-size: 17px;
    font-weight: 600;
  }
}

@media (min-width: 320px) and (max-width: 412px) {
  .cardsresponsive {
    margin-right: 290px;
  }

  .video {
    width: 100%;
    height: 118%;
    object-fit: cover;
  }

  .cardadd1 {
    display: none;
  }

  .cardadd3 {
    display: block;
    margin-left: 25px;
  }

  .cardadd2 {
    display: none;
  }

  .cardadd4 {
    display: block;
    margin-top: 10px;
    margin-left: 25px;
  }

  .secondcontentshow-title {
    padding: 0px 20px;
    font-size: 25px;
  }

  .secondcontentshow-para {
    padding: 0px 20px;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
  }

  .secondcontentshow-para1 {
    padding: 0px 20px;
    font-size: 16px;
    font-weight: 600;
  }
}

@media (min-width: 412px) and (max-width: 767px) {
  .cardsresponsive {
    margin-right: 290px;
    padding-bottom: 300px;
  }

  .video {
    height: 116%;
    width: 100%;
    object-fit: cover;
  }

  .cardadd1 {
    display: none;
  }

  .cardadd3 {
    display: block;
    margin-left: 25px;
  }

  .cardadd2 {
    display: none;
  }

  .cardadd4 {
    display: block;
    margin-top: 10px;
    margin-left: 25px;
  }

  .secondcontentshow-title {
    padding: 0px 20px;
    font-size: 25px;
  }

  .secondcontentshow-para {
    padding: 0px 20px;
    font-size: 17px;
    font-weight: 600;
    margin-left: 10px;
  }

  .secondcontentshow-para1 {
    padding: 0px 20px;
    font-size: 17px;
    font-weight: 600;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .cardsresponsive {
    padding-left: 150px;
  }

  .addbutton {
    padding-right: 30px;
  }

  .video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

@media (min-width: 992px) {
  .video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
@media (min-width: 1200px) {
  .cardsresponsive {
    padding-left: 450px;
  }
}

.cardadd1 {
  margin-top: 100px;
  height: 300px;
  width: 280px;
  background-color: #009846;
  border-radius: 20px;
}

.cardadd2 {
  margin-top: 20px;
  height: 300px;
  width: 280px;
  background-color: #c68955;
  border-radius: 20px;
}

.cardrow {
  height: 750px;
  width: 100%;
  position: relative;
}

.secondCard {
  margin-top: -600px;
  padding-left: 0px;
  width: 600px;
}

/* social media slid end */

.chat-text {
  padding: 20px;
  font-size: 18px;
  font-weight: 600;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  color: white;
  border-radius: 4px;
  background-color: #009846;
  border: 2px solid #009846;
  font-weight: 300;
  line-height: 130%;
  position: relative;
  margin-top: -655px;
  margin-left: 785px;
  height: max-content;
  width: 400px;
  z-index: 2;
}

.chat-text:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 10px;
  left: 0px;
  border: 22px solid;
  border-color: transparent #ffffff transparent transparent;
  right: -47px;
  border-color: transparent transparent transparent #009846;
  left: inherit;
}

.chat-text1 {
  padding: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: white;
  background-color: #c68955;
  border: 2px solid #c68955;
  font-weight: 300;
  line-height: 150%;
  position: relative;
  margin-top: -300px;
  margin-left: 500px;
  width: 600px;
}

.chat-text1:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 10px;
  left: 0px;
  border: 22px solid;
  border-color: transparent #ffffff transparent transparent;
  right: -47px;
  border-color: transparent transparent transparent #c68955;
  left: inherit;
}

.content-card {
  position: absolute;
  top: 0px;
  right: 30px;
  z-index: 1;
}

.addbutton {
  text-align: right;
  padding-right: 30px;
  font-size: 27px;
  color: #f7f7f7;
  padding-top: 20px;
}

.addcontent {
  font-size: 42px;
  padding: 30px;
  line-height: 35px;
  color: white;
  margin-top: 80px;
  font-weight: 500;
}

/*  responsive */
@media (max-width: 767px) {
  .cardrow {
    width: 100%;
  }

  .secondCard {
    margin-top: -600px;
    padding-left: 30px;
    width: 200px;
  }

  .cardadd1 {
    margin-top: 0px;
  }

  .chat-text {
    padding: 1px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background: #ffffff;
    border: 2px solid #009846;
    font-weight: 300;
    line-height: 150%;
    position: relative;
    margin-top: -630px;
    margin-left: 18px;
    width: 280px;
    font-size: 3px;
    height: 200px;
    line-height: 22px;
    z-index: 2;
  }

  .chat-text:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 10px;
    left: 0px;
    border: 22px solid;
    border-color: transparent #ffffff transparent transparent;
    right: -47px;
    border-color: transparent transparent transparent #009846;
    left: inherit;
  }

  .chat-text1 {
    padding: 3px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background: #ffffff;
    border: 2px solid #c68955;
    font-weight: 300;
    line-height: 150%;
    position: relative;
    margin-top: -290px;
    margin-left: 18px;
    width: 280px;
    font-size: 3px;
    height: 130px;
    line-height: 22px;
    z-index: 2;
  }

  .chat-text1:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 10px;
    left: 0px;
    border: 22px solid;
    border-color: transparent #ffffff transparent transparent;
    right: -47px;
    border-color: transparent transparent transparent #c68955;
    left: inherit;
  }

  .cardadd1 {
    margin-top: 100px;
    height: 300px;
    width: 280px;
    background-color: #009846;
    border-radius: 20px;
    margin-left: 30px;
  }

  .cardadd2 {
    margin-top: 20px;
    height: 300px;
    width: 280px;
    background-color: #c68955;
    border-radius: 20px;
    margin-left: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .secondCard {
    margin-top: -600px;
    padding-left: 0px;
    width: 200px;
  }

  .cardadd1 {
    margin-top: 100px;
    height: 300px;
    width: 280px;
    background-color: #009846;
    border-radius: 20px;
  }

  .cardadd2 {
    margin-top: 20px;
    height: 300px;
    width: 280px;
    background-color: #c68955;
    border-radius: 20px;
  }

  .addbutton {
    text-align: right;
    padding-right: 30px;
    font-size: 27px;
    color: #f7f7f7;
    padding-top: 20px;
  }

  .chat-text {
    padding: 5px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background: #ffffff;
    border: 2px solid #009846;
    font-weight: 300;
    line-height: 150%;
    position: relative;
    margin-top: -630px;
    margin-left: 30px;
    width: 300px;
    font-size: 4px;
    height: 160px;
    line-height: 20px;
  }

  .chat-text:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 10px;
    left: 0px;
    border: 22px solid;
    border-color: transparent #ffffff transparent transparent;
    right: -47px;
    border-color: transparent transparent transparent #009846;
    left: inherit;
  }

  .chat-text1 {
    padding: 5px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background: #ffffff;
    border: 2px solid #c68955;
    font-weight: 300;
    line-height: 150%;
    position: relative;
    margin-top: -300px;
    margin-left: 30px;
    width: 300px;
    font-size: 4px;
    height: 130px;
    line-height: 20px;
  }

  .chat-text1:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 10px;
    left: 0px;
    border: 22px solid;
    border-color: transparent #ffffff transparent transparent;
    right: -47px;
    border-color: transparent transparent transparent #c68955;
    left: inherit;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .cardsresponsive {
    padding-left: 210px;
  }
}
@media (max-width: 1280px) and (min-width: 800px) {
  .cardsresponsive {
    padding-left: 150px;
  }
}

/* -------------------Video Card Section End------------------- */

/* --------------------different between cng , petrol,diesel----------  */
.bulb-image {
  width: 75%;
  margin-left: 120px;
}

.card-mobile-res {
  display: none;
}

.arrow-down {
  color: #009846;
}

.arrow-up {
  color: #5e17eb;
}

.card-main {
  padding: 0px 0px 0px 0px;
}

.title {
  margin: 30px 0px 0px 180px;
}

.cng-title {
  padding: 5px;
  font-size: 25px;
  font-weight: 700;
  width: 100px;
  height: 50px;
  margin-top: 70px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-color: #000000;
}

.petrol-title {
  width: 100px;
  height: 50px;
  padding: 5px;
  font-size: 25px;
  font-weight: 700;
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-color: #000000;
  margin-left: 20px;
}

.diesel-title {
  width: 100px;
  height: 50px;
  padding: 5px;
  font-size: 25px;
  font-weight: 800;
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-color: #000000;
  margin-left: 30px;
}

.circle {
  margin-bottom: -40px;
}

.ce-card {
  width: 90%;
  border-radius: 30px;
  color: #ffffff;
  margin: 0px 0px 0px 100px;
  border: 6px solid black;
}

.card-low,
.card-high {
  font-size: 22px;
  font-weight: 600;
}

.card-content-left {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.card-content-left p {
  font-size: 25px;
  font-weight: 700;
}

.text-weight {
  font-weight: bold;
}

.card-center-border {
  position: relative;
}

.card-center-border::after {
  content: " ";
  position: absolute;
  top: 10px;
  /* width: 1px; */
  height: 430px;
  left: 120px;
  border: 1px solid #d2cecc;
}

.border-bottom-line {
  position: relative;
}

.border-bottom-line::after {
  content: " ";
  position: absolute;
  top: 40px;
  width: 225px;
  height: 2px;
  left: 15px;
  background-color: #cfcbc9;
}

.arrow-border-bottom {
  position: relative;
}

.arrow-border-bottom::after {
  content: " ";
  position: absolute;
  top: 40px;
  width: 80px;
  height: 2px;
  left: 10px;
  background-color: #cfcbc9;
}

.cards {
  padding: 10px;
}

/* Card slider */
.gtco-testimonials {
  justify-content: center;
  position: relative;
  margin-top: 200px;
  padding: 0 50px;
  width: 100%;
}

.gtco-testimonials .card {
  background: #fff;
  box-shadow: 0 8px 30px -7px #c9dff0;
  margin: 0 20px;
  padding: 0 10px;
  border-radius: 20px;
  border: 0;
  transition: transform 0.5s;
}

.gtco-testimonials .card .card-img-top {
  max-width: 300px;
  margin: 15px auto 0;
  box-shadow: 0 8px 20px -4px #95abbb;
  width: 350px;
  height: 380px;
}

.gtco-testimonials .slick-center .card {
  transform: scale(1.2);
}

@media (max-width: 319px) {
  .gtco-testimonials {
    margin-top: 20px;
  }

  .card-main {
    padding: 40px 0px 0px 0px;
    width: 110%;
    margin-top: -150px;
  }

  .bulb-image {
    display: none;
    /* height: auto;
      display: flex;
      margin-left: 40px; */
  }

  .cards-mobile-none {
    display: none;
  }

  .m-low-hign {
    padding-left: 10px;
  }

  .m-low-hign p {
    font-size: 15px;
  }

  .m-cng-title {
    padding: 5px;
    font-size: 13px;
    font-weight: 700;
    width: 50px;
    height: 30px;
    margin-top: 70px;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background-color: #000000;
  }

  .m-petrol-title {
    width: 50px;
    height: 30px;
    padding: 10px;
    font-size: 13px;
    font-weight: 700;
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background-color: #000000;
    margin-left: 17px;
  }

  .m-diesel-title {
    width: 50px;
    height: 30px;
    padding: 10px;
    font-size: 13px;
    font-weight: 800;
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background-color: #000000;
    margin-left: 10px;
  }

  .card-mobile-res {
    display: block;
    margin-top: 170px;
  }

  .m-card-left {
    margin: 0px 5px 0px 15px;
  }

  .m-card-left p {
    font-size: 8px;
    font-weight: 600;
  }

  .left-content-width {
    width: 40%;
  }

  .m-border-bottom-line {
    position: relative;
  }

  .m-border-bottom-line::after {
    content: " ";
    position: absolute;
    top: 20px;
    width: 100px;
    height: 1px;
    left: 10px;
    background-color: #cfcbc9;
  }

  .m-arrow-border-bottom {
    position: relative;
  }

  .m-arrow-border-bottom::after {
    content: " ";
    position: absolute;
    top: 20px;
    width: 40px;
    height: 1px;
    left: -17px;
    background-color: #cfcbc9;
  }

  .m-card-center-border {
    position: relative;
  }

  .m-card-center-border::after {
    content: " ";
    position: absolute;
    top: 10px;
    /* width: 1px; */
    height: 255px;
    left: -0px;
    border: 1px solid #d2cecc;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .gtco-testimonials {
    margin-top: 20px;
  }

  .card-main {
    padding: 50px 0px 0px 0px;
    width: 100%;
    margin-top: -150px;
  }

  .bulb-image {
    display: none;
    /* height: auto;
      display: flex;
      margin-left: 40px; */
  }

  .cards-mobile-none {
    display: none;
  }

  .m-low-hign {
    padding-left: 90px;
  }

  .m-low-hign p {
    font-size: 15px;
  }

  .m-cng-title {
    padding: 5px;
    font-size: 13px;
    font-weight: 700;
    width: 50px;
    height: 30px;
    margin-top: 70px;
    margin-left: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background-color: #000000;
  }

  .m-petrol-title {
    width: 50px;
    height: 30px;
    padding: 10px;
    font-size: 13px;
    font-weight: 700;
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background-color: #000000;
    margin-left: 17px;
  }

  .m-diesel-title {
    width: 50px;
    height: 30px;
    padding: 10px;
    font-size: 13px;
    font-weight: 800;
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background-color: #000000;
    margin-left: 10px;
  }

  .card-mobile-res {
    display: block;
    margin-top: 170px;
  }

  .m-card-left {
    margin: 0px 10px 0px 30px;
  }

  .m-card-left p {
    font-size: 10px;
    font-weight: 600;
  }

  .left-content-width {
    width: 45%;
  }

  .m-border-bottom-line {
    position: relative;
  }

  .m-border-bottom-line::after {
    content: " ";
    position: absolute;
    top: 20px;
    width: 100px;
    height: 1px;
    left: 10px;
    background-color: #cfcbc9;
  }

  .m-arrow-border-bottom {
    position: relative;
  }

  .m-arrow-border-bottom::after {
    content: " ";
    position: absolute;
    top: 20px;
    width: 40px;
    height: 1px;
    left: -17px;
    background-color: #cfcbc9;
  }

  .m-card-center-border {
    position: relative;
  }

  .m-card-center-border::after {
    content: " ";
    position: absolute;
    top: 10px;
    /* width: 1px; */
    height: 255px;
    left: -0px;
    border: 1px solid #d2cecc;
  }
}

@media (min-width: 767px) and (max-width: 1200px) {
  .card-main {
    margin-top: -80px;
    margin-left: -70px;
    padding-right: 30px;
    margin-right: 30px;
    overflow: hidden;
  }

  .cng-title {
    padding: 5px;
    font-size: 23px;
    font-weight: 700;
    width: 90px;
    height: 45px;
    margin-left: 80px;
  }

  .petrol-title {
    padding: 10px;
    font-size: 23px;
    font-weight: 700;
    width: 90px;
    height: 45px;
    margin-left: 70px;
  }

  .diesel-title {
    padding: 10px;
    font-size: 23px;
    font-weight: 700;
    width: 90px;
    height: 45px;
    margin-left: 50px;
  }

  .bulb-image {
    /* margin-left: 170px; */
    display: none;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .ce-card {
    width: 140%;
  }

  .card-main {
    width: 100%;
    margin: -40px 0px 0px 0px;
  }

  .cng-title {
    margin-left: 100px;
  }

  .petrol-title {
    margin-left: 160px;
  }

  .diesel-title {
    margin-left: 220px;
  }
}

/* --------------------different between cng , petrol,diesel end----------  */

/* -------------------5 Card Section Start------------------- */
.card-text {
  font-size: 18px;
}

.tree-image {
  width: 60px;
  margin: 20px 0px 0px 30px;
}

.energyimage {
  height: 450px;
  width: 280px;
  margin-left: 280px;
  margin-top: -50px;
}

.cards-title {
  font-size: 20px;
  text-align: right;
  margin-right: 8px;
  margin-top: 17px;
  font-weight: 800;
}

.card-text-padding {
  font-size: 20px;
  text-align: right;
  margin-right: 10px;
  margin-top: 40px;
}

.cardrowheight {
  height: 120px;
}

.cardrowheight1 {
  height: 100px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .card-text {
    font-size: 17px;
    margin-top: 40px;
  }

  .cards-title {
    font-size: 18px;
    text-align: center;
    margin-right: 0px;
  }

  .energyimage {
    /* height: 300px;
        width: 280px;
        margin-left: 150px;
        margin-top: 100px; */
    display: none;
  }
}

@media (max-width: 767px) {
  .tree-image {
    width: 60px;
    margin: 20px 0px 0px 0px;
  }

  .image-center-card {
    text-align: center;
  }

  .card-text {
    font-size: 17px;
    padding-top: 20px;
  }

  .cards-title {
    margin-top: 0px;
    font-size: 17px;
    text-align: center;
  }

  .card-text-padding {
    font-size: 17px;
    text-align: right;
    margin-right: 10px;
    margin-top: 5px;
  }
}

.title2 {
  margin-top: 50px;
  margin-left: 0px;
  margin-bottom: 40px;
}

.cardborder {
  border-bottom: 7px solid #009846;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  min-height: 360px;
  width: 270px;
  margin-bottom: 30px;
}

.cardspace {
  margin-top: 100px;
}

.tab-view-show {
  display: none;
}

@media (max-width: 319px) {
  .gamechanger-cards-padding {
    width: 100%;
    padding: 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cardrowspace {
    margin-left: 0px;
  }

  .cardborder {
    margin-left: 0px;
    margin-right: 10px;
  }

  .cardspace {
    margin-top: 0px;
  }

  .title2 {
    margin-top: 30px;
    margin-left: 10px;
    margin-bottom: 40px;
    text-align: center;
  }

  .card-text {
    padding: 10px;
    font-size: 10px;
  }
}

@media (min-width: 320px) and (max-width: 411px) {
  .gamechanger-cards-padding {
    width: 100%;
    padding: 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cardrowspace {
    /* width: 100%; */
    margin-left: 0px;
  }

  .cardborder {
    margin-bottom: 20px;
  }

  .cardspace {
    margin-top: 0px;
  }

  .title2 {
    margin-top: 30px;
    margin-left: 10px;
    margin-bottom: 40px;
    text-align: center;
  }

  .tab-view-center-card {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .m-responsive-center {
    margin-left: 57px;
  }
}

@media (min-width: 412px) and (max-width: 767px) {
  .gamechanger-cards-padding {
    width: 100%;
    padding: 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cardrowspace {
    /* width: 100%; */
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cardborder {
    margin-bottom: 20px;
    border-bottom: 7px solid #009846;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    min-height: 360px;
    width: 280px;
  }

  .cardspace {
    margin-top: 0px;
  }

  /* .m-padding {
        padding: 0px 15px;
    } */
  .title2 {
    margin-top: 30px;
    margin-left: 10px;
    margin-bottom: 40px;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .energyimage {
    display: none;
    /* height: 200px;
        width: 180px;
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 30px; */
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .gamechanger-cards-padding {
    padding: 0px 0px;
  }

  .cardborder {
    border-bottom: 7px solid #009846;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    height: 400px;
    width: 300px;
  }

  .cardrowspace {
    margin-left: 10px;
  }

  .cardspace {
    margin-top: 100px;
    margin-left: 120px;
  }

  .tree-image {
    width: 60px;
    margin: 20px 0px 0px 0px;
  }

  .image-center-card {
    text-align: center;
  }

  .tab-view-show {
    display: block;
  }

  .tab-view-hide-card {
    display: none;
  }

  .tab-view-center-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title2 {
    margin-left: 60px;
  }
}

@media (min-width: 992px) {
  .gamechanger-cards-padding {
    padding: 0px 80px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .gamechanger-cards-padding {
    padding: 0px 30px;
  }

  .cardborder {
    width: 235px;
  }

  .energyimage {
    height: 400px;
    margin-left: 170px;
    margin-top: -20px;
  }
}

/* -------------------5 Card Section End------------------- */

/*--------------------- Faq Section End---------------- */

/* .faq p{
    font-family: 'Genos';
  } */

.accordions {
  width: 100%;
  padding: 0px 0px;
  margin: 100px auto;
}

.accordions h3 {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.accordion-item {
  margin-bottom: 20px;

  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 15px 10px -12px;
}

.accordion-item .accordion-title {
  cursor: pointer;
  padding: 20px;
  transition: transform 0.8s ease-in-out;
}

.accordion-item .accordion-title.active-title {
  background-color: #009846;
  color: #ffffff;
}

.accordion-item .accordion-title p {
  margin: 0;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
}

.accordion-item .accordion-title i.fa-chevron-down {
  transform: rotate(0);
  transition: 0.4s;
}

.accordion-item .accordion-title i.fa-chevron-down.chevron-top {
  transform: rotate(-180deg);
  color: #fa5019;
}

.accordion-item .accordion-content {
  display: none;
  line-height: 1.7;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 0 0 5px 5px;
  color: #100e34;
}

.accordion-item .accordion-content p {
  font-size: 17px;
}

.accordion-item .accordion-content.active {
  display: block;
}

.accordion-item .accordion-content p {
  margin: 0;
  /* font-family: "Nunito Sans", sans-serif; */
  font-size: 19px;
}

.details {
  background: #dce1f2;
}

.details .detailed_info {
  margin: 50px auto;
}

.details img {
  margin: 0 auto;
  display: block;
  /* margin-top: 120px; */
}

.details h3 {
  font-weight: bold;
  font-size: 20px;
}

.details p {
  font-size: 16px;
  line-height: 1.5em;
}

.details ul li {
  font-size: 16px;
  line-height: 2em;
}

.faq-padding-responsive {
  padding: 30px 50px;
}

.faq-title {
  font-size: xx-large;
  padding: 0px 90px;
  color: #009846;
  font-weight: 700;
}

.faq {
  padding: 0px 0px 0px 60px;
}

.second-faq {
  margin-top: -50px;
}

.second-para-faq {
  padding: 0px 90px 0px 0px;
}

@media (max-width: 767px) {
  .faq-padding-responsive {
    padding: 10px 0px;
  }

  .faq {
    padding: 0px 0px 0px 0px;
  }

  .second-faq {
    margin-top: -180px;
  }

  .second-para-faq {
    padding: 0px 0px 0px 0px;
  }

  .faq-title {
    font-size: xx-large;
    padding: 0px 40px;
    color: #009846;
    font-weight: 700;
  }

  .accordions {
    width: 100%;
    padding: 0px 0px;
    margin: 100px auto;
  }

  .accordion-item .accordion-title.active-title p {
    font-weight: 550;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .accordion-item .accordion-title {
    padding: 5px;
  }

  .faq {
    padding: 0px 0px 0px 0px;
  }

  .second-para-faq {
    padding: 0px 0px 0px 0px;
  }

  .faq-title {
    font-size: xx-large;
    padding: 0px 30px;
    color: #009846;
    font-weight: 700;
  }
}

/*--------------------- Faq Section End---------------- */
