.privacy-main{
    padding: 0px 50px;
  }
  .privacy-title{
    text-align: center;
  }
  .card-shadow{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  @media (max-width:677px){
    .privacy-main{
      padding: 0px 0px;
    }
    .privacy-title{
      text-align: center;
    }
    .privacy-main p{
      font-size: 17px;
    }
    .privacy-main ul li{
      font-size: 17px;
    }
    
  }
  